<template>
  <Breadcrumb icon="icon-video" :list="['内容管理', '词汇库']"/>
  <div class="table-tools">
    <el-form :inline="true" :model="searchForm" label-width="100px" ref="ruleFormRef">
      <el-form-item label="本义场归属：" prop="levelOne">
        <el-select style="width: 140px; margin-right: 10px;" v-model="searchForm.levelOne" filterable placeholder="请选择" @change="changeLevelOne">
          <el-option v-for="item in selectList[0]" :key="item.id" :label="item.name" :value="item.id"/>
        </el-select>
        <el-select style="width: 140px; margin-right: 10px;" v-model="searchForm.levelTwo" filterable placeholder="请选择" @change="changeLevelTwo">
          <el-option v-for="item in selectList[1]" :key="item.id" :label="item.name" :value="item.id"/>
        </el-select>
        <el-select style="width: 140px;" clearable v-model="searchForm.levelThree" filterable placeholder="请选择">
          <el-option v-for="item in selectList[2]" :key="item.id" :label="item.name" :value="item.id"/>
        </el-select>
      </el-form-item>
      <el-form-item label="无义场词库：" prop="isWordsRelationExtension">
        <el-radio-group v-model="searchForm.isWordsRelationExtension">
          <el-radio-button :label="null">全部</el-radio-button>
          <el-radio-button :label="false">否</el-radio-button>
          <el-radio-button :label="true">是</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="词义场形拓：" prop="relationId">
        <el-select v-model="searchForm.relationId" placeholder="请选择" clearable
         :loading="mainRelatedWordLoading" filterable remote :remote-method="remoteMethod">
          <el-option v-for="item in mainWordData" :key="item.id" :label="item.name" :value="item.id"/>
        </el-select>
      </el-form-item>
      <el-form-item label="所属词库：" prop="lexicon">
        <el-select v-model="searchForm.lexicon" filterable placeholder="请选择" clearable>
          <el-option v-for="item in ckData" :key="item.id" :label="item.name" :value="item.id"/>
        </el-select>
      </el-form-item>
      <el-form-item label="单词：" prop="name">
        <el-input v-model="searchForm.name" placeholder="请输入"/>
      </el-form-item>
      <el-form-item class="form-item-buttons">
        <el-button type="primary" @click="getList({page: 1})">查询</el-button>
        <el-button type="default" @click="resetForm(ruleFormRef)">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="buttons">
      <el-button class="button" type="primary" @click="addItem">新增单词</el-button>
    </div>
  </div>
  <div class="table">
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column type="index" width="54" label="序号" />
      <el-table-column prop="name" label="单词" />
      <el-table-column prop="sort" label="本义场归属" width="250">
        <template #default="scope">
          <ChkYc :list="scope.row.righteousFileldName" />
        </template>
      </el-table-column>
      <el-table-column prop="count" label="进入迷途次数" width="120" />
      <el-table-column prop="isDisable" label="词义场形拓" width="120">
        <template #default="scope">
          <div style="text-align: center;" v-for="(item, index) in scope.row.relateName" :key="index">{{ item }}</div>
          <div style="text-align: center;" v-if="scope.row.relateName.length <= 0">——</div>
        </template>
      </el-table-column>
      <el-table-column prop="isDisable" label="所属词库">
        <template #default="scope">
          <div style="text-align: center;" v-for="(item, index) in scope.row.lexiconName" :key="index">{{ item }}</div>
          <div style="text-align: center;" v-if="scope.row.lexiconName.length <= 0">——</div>
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="最近操作时间" width="120" />
      <el-table-column label="操作" width="120">
        <template #default="scope">
          <el-button link type="primary" size="small" @click="editItem(scope.row)">编辑</el-button>
          <el-button link type="primary" size="small" @click="doDeleteItem(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page">
      <el-pagination
        v-model:currentPage="searchForm.page"
        :page-size="searchForm.pageSize"
        layout="total, prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog v-model="addVisible" title="新增单词" width="90%" top="3vh">
      <AddForm v-if="addVisible" @closeAndRefresh="closeAndRefresh" :selectListOne="selectList[0]"/>
    </el-dialog>
    <el-dialog v-model="editVisible" title="编辑单词" width="90%" top="3vh">
      <EditForm v-if="editVisible" @closeAndRefresh="closeAndRefresh" :item="currentItem" :selectListOne="selectList[0]"/>
    </el-dialog>
  </div>
</template>

<script setup>

import { reactive, ref, onMounted } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { selectByType } from '@/api/righteousField'
import { selectAll as selectAllCK } from '@/api/lexicon'
import { selectByPage, selectAll as selectAllWord, deleteWord } from '@/api/word'
import AddForm from './Add'
import EditForm from './Edit'

const ruleFormRef = ref()

const searchForm = reactive({
  page: 1,
  pageSize: 10,
  isWordsRelationExtension: null
})

const tableData = ref([])
const total = ref(0)

const getList = async (params) => {
  Object.assign(searchForm, params)
  const {data} = await selectByPage({
    ...searchForm
  })
  tableData.value = data.items
  total.value = data.total
}

const resetForm = (formEl) => {
  if (!formEl) return
  searchForm.levelTwo = ''
  searchForm.levelThree = ''
  formEl.resetFields()
  getList({page: 1})
}

const selectList = reactive([[], [], []])
const getSelectByType = async (params) => {
  const {data} = await selectByType(params)
  selectList[params.type] = data
} 

const changeLevelOne = (id) => {
  getSelectByType({type: 1, id})
  selectList[1] = []
  selectList[2] = []
}
const changeLevelTwo = (id) => {
  getSelectByType({type: 2, id})
  selectList[2] = []
}

const ckData = ref([])
const getSelectAllCK = async () => {
  const {data} = await selectAllCK()
  ckData.value = data
}

// const wordData = ref([])
// const getSelectAllWord = async () => {
//   const {data} = await selectAllWord()
//   wordData.value = data
// }

const mainRelatedWordLoading = ref(false)
const mainWordData = ref([])
const remoteMethod = async (name) => {
  if (name !== '') {
      mainRelatedWordLoading.value = true
      const {data} = await selectAllWord({name})
      mainRelatedWordLoading.value = false
      mainWordData.value = data
    } else {
      mainWordData.value = []
    }
}

onMounted(() => {
  getList()
  getSelectByType({type: 0})
  getSelectAllCK()
  // getSelectAllWord()
})

const handleSizeChange = (size) => {
  searchForm.pageSize = size
  getList()
}
const handleCurrentChange = (page) => {
  searchForm.page = page
  getList()
}

const changeDisable = async (item) => {
  const {id, isDisable} = item
  await disableLexicon({id, isDisable})
  getList()
}

const doDeleteItem = async (item) => {
  await ElMessageBox.confirm(
    '确定要删除吗?',
    '友情提示'
  )
  const {id} = item
  await deleteWord({id})
  getList()
}

const addVisible = ref(false)
const addItem = () => {
  addVisible.value = true
}

const closeAndRefresh = (refresh) => {
  addVisible.value = false
  editVisible.value = false
  if (refresh) {
    getList()
  }
}

const editVisible = ref(false)
const currentItem = ref({})
const editItem = (item) => {
  currentItem.value = item
  editVisible.value = true
}

const changeSort = async (item) => {
  const {id, sort} = item
  const {message} = await sortLexicon({id, sort})
  if (message == 'OK') {
    getList()
  }
}
</script>