import request from '@/utils/request'

/**
 * 分页查询
 * @param {page, pageSize, operator, operatingStartTime, operatingEndTime} data 
 * @returns 
 */
export function selectByPage(data) {
  return request({
    url: `/log/selectByPage`,
    method: 'post',
    data
  })
}
