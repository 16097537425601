<template>
  <div class="table">
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column type="index" label="序号" width="54"/>
      <el-table-column prop="buyContent" label="购买内容">
        <template #default="scope">
          {{ getContantLabelByValue(scope.row.buyContent, ChargeBuyContent) }}
        </template>
      </el-table-column>
      <el-table-column prop="validTime" label="有效时间">
        <template #default="scope">
          {{ getContantLabelByValue(scope.row.validTime, ChargeValidTime) }}
        </template>
      </el-table-column>
      <el-table-column prop="money" label="收费标准(元)" />
      <el-table-column prop="rule" label="规则说明" />
      <el-table-column label="操作">
        <template #default="scope">
          <el-button link type="primary" size="small" @click="editItem(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-drawer v-model="addVisible" title="新增费用项目" direction="rtl" size="30%">
      <AddForm @closeAndRefresh="closeAndRefresh"/>
    </el-drawer>
    <el-drawer v-model="editVisible" title="编辑费用项目" direction="rtl" size="30%">
      <EditForm v-if="editVisible" @closeAndRefresh="closeAndRefresh" :item="currentItem"/>
    </el-drawer>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { selectAll } from '@/api/chargeProject'
import AddForm from './Add'
import EditForm from './Edit'
import {ChargeValidTime, ChargeBuyContent} from '@/contant'

const tableData = ref([])
const total = ref(0)

const getList = async () => {
  const {data} = await selectAll()
  tableData.value = data
}

const getContantLabelByValue = (value, list) => {
  if (value === '' || value < 0) return  '' 
  const item = list.find(item => item.value == value)
  if (!item) return ''
  return item.label
}

onMounted(() => {
  getList()
})

const addVisible = ref(false)
const addItem = () => {
  addVisible.value = true
}

const closeAndRefresh = (refresh) => {
  addVisible.value = false
  editVisible.value = false
  if (refresh) {
    getList()
  }
}

const editVisible = ref(false)
const currentItem = ref({})
const editItem = (item) => {
  currentItem.value = item
  editVisible.value = true
}

</script>
