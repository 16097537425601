<template>
  <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="120px" status-icon>
     <el-form-item label="订单编号：" prop="code">
       <div>{{ ruleForm.code }}</div>
    </el-form-item>
     <el-form-item label="用户编号：" prop="userCode">
       <div>{{ ruleForm.userCode }}</div>
    </el-form-item>
     <el-form-item label="昵称：" prop="nickname">
       <div>{{ ruleForm.nickname }}</div>
    </el-form-item>
     <el-form-item label="手机号：" prop="phone">
       <div>{{ ruleForm.phone }}</div>
    </el-form-item>
     <el-form-item label="购买内容：" prop="buyContent">
       <div>{{ getContantLabelByValue(ruleForm.buyContent, ChargeBuyContent) }}</div>
    </el-form-item>
     <el-form-item label="数量：" prop="number">
       <div>{{ ruleForm.number }}</div>
    </el-form-item>
     <el-form-item label="消费总额(元)：" prop="money">
       <div>{{ ruleForm.money }}</div>
    </el-form-item>
     <el-form-item label="购买时间：" prop="buyTime">
       <div>{{ ruleForm.buyTime }}</div>
    </el-form-item>
    <el-divider content-position="left">收件信息：</el-divider>
     <el-form-item label="收件人：" prop="recipients">
       <div>{{ ruleForm.recipients }}</div>
    </el-form-item>
     <el-form-item label="联系电话：" prop="recipientsPhone">
       <div>{{ ruleForm.recipientsPhone }}</div>
    </el-form-item>
     <el-form-item label="收件地址：" prop="provinces">
       <div>{{ ruleForm.provinces }}{{ ruleForm.address }}</div>
    </el-form-item>
    <!-- <el-form-item>
      <el-button type="primary" @click="resetForm(ruleFormRef)">确定</el-button>
      <el-button @click="resetForm(ruleFormRef)">取消</el-button>
    </el-form-item> -->
  </el-form>
</template>

<script setup>
import { reactive, ref, defineEmits, defineProps, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import { Plus } from '@element-plus/icons-vue'
import { selectById } from '@/api/order'
import {ChargeValidTime, ChargeBuyContent} from '@/contant'

const ruleForm = ref({})

const isLoading = ref(true)
const getDetailById = async () => {
  const { id } = props.item
  isLoading.value = true
  const {data} = await selectById({ id })
  isLoading.value = false
  ruleForm.value = data
}

const getContantLabelByValue = (value, list) => {
  if (value === '' || value < 0) return  '' 
  const item = list.find(item => item.value == value)
  if (!item) return ''
  return item.label
}

onMounted(() => {
  getDetailById()
})

const props = defineProps({
  item: Object
})

const em = defineEmits(['closeAndRefresh'])

const ruleFormRef = ref()

const rules = reactive({
})

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
  em('closeAndRefresh', false)
}

const headers = ref({Authorization: `Bearer ${window.localStorage.getItem('token')}`})

const handleAvatarSuccess = (response,uploadFile) => {
  ruleForm.value.photo = response.data
}

const beforeAvatarUpload = (rawFile) => {
  return true
}

</script>
