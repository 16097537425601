<template>
  <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="100px" status-icon>
    <el-form-item label="单词：" prop="name">
      <el-input v-model="ruleForm.name" style="width: 160px;"/>
    </el-form-item>
    <el-form-item label="释义：" prop="paraphrase">
      <!-- <el-input type="textarea" rows="5" v-model="ruleForm.paraphrase" style="width: 520px;"/> -->
      <TEditor class="agreement-teditor" v-model="ruleForm.paraphrase" />
    </el-form-item>
    <el-form-item label="隶属义场：" prop="isRelation">
      <el-switch :active-value="true" :inactive-value="false" v-model="ruleForm.isRelation" />
      <div style="width: 100%" v-if="ruleForm.isRelation">
        <ChkHasYc :list="selectListOne" v-for="(item, index) in ruleForm.wordRighteousFieldList" :key="item.id" :righteousFieldItem="item" @deleteItem="() => deleteWordRighteousField(index)"/>
        <el-button :icon="Plus" @click="addWordRighteousField">添加</el-button>
      </div>
    </el-form-item>
    <el-form-item label="图片：" prop="isDisable">
      <el-upload
        v-model:file-list="fileList"
        action="/api/word/upload"
        list-type="picture-card"
        :headers="headers"
        :on-success="handleUploadSuccess"
        :before-upload="beforeUpload"
        :limit="4"
      >
        <el-icon><Plus /></el-icon>
      </el-upload>
    </el-form-item>
    <el-form-item label="英音：" prop="fileYY">
      <el-upload
        v-model:file-list="fileYYList"
        action="/api/word/uploadFile"
        :headers="headers"
        :on-success="handleUploadYYSuccess"
        :before-upload="beforeUploadYY"
        :limit="1"
      >
        <el-button type="primary">请选择文件上传</el-button>
      </el-upload>
    </el-form-item>
    <el-form-item label="美音：" prop="fileMY">
      <el-upload
        v-model:file-list="fileMYList"
        action="/api/word/uploadFile"
        :headers="headers"
        :on-success="handleUploadMYSuccess"
        :before-upload="beforeUploadMY"
        :limit="1"
      >
        <el-button type="primary">请选择文件上传</el-button>
      </el-upload>
    </el-form-item>
    <el-form-item label="所属词库：" prop="wordLexiconList">
      <el-select v-model="wordLexiconList" multiple placeholder="请选择">
        <el-option v-for="item in ckList" :key="item.id"
          :label="item.name" :value="item.id">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button @click="openExpand()">形拓配置</el-button>
      <el-button type="primary" @click="submitForm(ruleFormRef)">保存</el-button>
    </el-form-item>
  </el-form>

  <div style="position: relative; z-index: 10001">
    <el-dialog v-model="expandVisible" title="形拓配置" width="90%" top="3vh" style="z-index: 10001">
      <WordExpand @close="closeExpand" :item="ruleForm"/>
    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, ref, defineEmits, defineProps, onMounted } from 'vue'
import { add } from '@/api/word'
import { selectAll } from '@/api/lexicon'
import { Plus } from '@element-plus/icons-vue'
import WordExpand from './WordExpand'
import { ElMessage } from 'element-plus'

const ruleForm = ref({
  wordAudioList: [],
  wordLexiconList: [],
  isRelation: false,
  wordRighteousFieldList: [],
  isFormRelation: false,
  wordMorphologicalRighteousList: [],
  wordMorphologicalList: [],
  isWordsRelationExtension: false,
  wordSemanticWordList: []
})

const ckList = ref([])
const getCkList = async () => {
  const {data} = await selectAll()
  ckList.value = data
}

onMounted(() => {
  getCkList()
})

const props = defineProps({
  item: Object,
  selectListOne: Array
})

const em = defineEmits(['closeAndRefresh'])

const ruleFormRef = ref()

const validateName = (rule, value, callback) => {
  if (value.trim() === '') {
    callback(new Error('请输入单词名称'))
  } else {
    const reg = /^[A-Za-z0-9\…\…\（\）\(\) \-\'\/\.]+$/
    if (!reg.test(value)) {
      callback(new Error('单词不合法，请输入正确的单词'))
    }
    callback()
  }
}

const rules = reactive({
  name: [
    { required: true, message: '请输入单词名称', trigger: 'blur' },
    { validator: validateName, trigger: 'blur' }
  ],
  paraphrase: [
    { required: true, message: '请输入释义内容', trigger: 'blur' }
  ],
  // fileMY: [
  //   { required: true, message: '请上传美音文件', trigger: 'change' },
  // ],
  // fileYY: [
  //   { required: true, message: '请上传英音文件', trigger: 'change' },
  // ]
})

const wordLexiconList = ref([])

const headers = ref({Authorization: `Bearer ${window.localStorage.getItem('token')}`})
const fileList = ref([])
const handleUploadSuccess = (response, uploadFile) => {
  // fileList.value.push({
  //   name: uploadFile.name,
  //   url: response.data
  // })
}

const beforeUpload = (rawFile) => {
  if (fileList.value.length >= 4) {
    return false
  }
  return true
}

const fileYYList = ref([])
const handleUploadYYSuccess = (response, uploadFile) => {
  // fileYYList.value.push({
  //   name: uploadFile.name,
  //   url: response.data
  // })
  ruleForm.value.fileYY = true
}

const beforeUploadYY = (rawFile) => {
  if (fileYYList.value.length >= 1) {
    return false
  }
  return true
}


const fileMYList = ref([])
const handleUploadMYSuccess = (response, uploadFile) => {
  // fileYYList.value.push({
  //   name: uploadFile.name,
  //   url: response.data
  // })
  ruleForm.value.fileMY = true
}

const beforeUploadMY = (rawFile) => {
  if (fileMYList.value.length >= 1) {
    return false
  }
  return true
}

const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate()
  
  const picture = []
  fileList.value.map(file => picture.push(file.response && file.response.data || file.url))
  ruleForm.value.picture = picture

  const wordLexiconListArr = wordLexiconList.value.map(item => ({lexiconId: item}))
  ruleForm.value.wordLexiconList = wordLexiconListArr

  // ruleForm.value.wordAudioList.map(audio => {
  //   if (audio.type == 0) {
  //     if (fileYYList.value.length > 0) {
  //       audio.url = fileYYList.value[0].url || fileYYList.value[0].response.data
  //     } else {
  //       audio.url = ''
  //     }
  //   }
  //   if (audio.type == 1) {
  //     if (fileMYList.value.length > 0) {
  //       audio.url = fileMYList.value[0].url || fileMYList.value[0].response.data
  //     } else {
  //       audio.url = ''
  //     }
  //   }
  // })

  const wordAudioList = []
  if (fileYYList.value.length > 0) {
    wordAudioList.push({
      type: 0,
      url: fileYYList.value[0].url || fileYYList.value[0].response.data || ''
    })
  }
  
  if (fileMYList.value.length > 0) {
    wordAudioList.push({
      type: 1,
      url: fileMYList.value[0].url || fileMYList.value[0].response.data || ''
    })
  }
  ruleForm.value.wordAudioList = wordAudioList

  const wordSemanticWordList = []
  const temp = [...ruleForm.value.wordSemanticWordList]
  ruleForm.value.wordSemanticWordList.map(w => {
    const rightPicture = []
    w.fileList.map(file => rightPicture.push(file.response && file.response.data || file.url || file))
    const errorPicture = []
    w.fileList2.map(file => errorPicture.push(file.response && file.response.data || file.url || file))
    wordSemanticWordList.push({
      relatedWordId: w.relatedWordId,
      gameType: w.gameType,
      rightPicture,
      errorPicture
    })
  })
  ruleForm.value.wordSemanticWordList = wordSemanticWordList
  const {message} = await add(ruleForm.value)
  if (message == 'OK') {
    em('closeAndRefresh', true)
  } else {
    ruleForm.value.wordSemanticWordList = [...temp]
  }
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
  em('closeAndRefresh', false)
}

const deleteWordRighteousField = (index) => {
  ruleForm.value.wordRighteousFieldList.splice(index, 1)
}

const addWordRighteousField = () => {
  ruleForm.value.wordRighteousFieldList.push({})
}

// 形拓配置
const expandVisible = ref(false)
const openExpand = () => {
  expandVisible.value = true
}
const closeExpand = () => {
  expandVisible.value = false
}
</script>
