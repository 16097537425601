<template>
  <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="100px" status-icon>
    <el-form-item label="昵称：">
      <el-input v-model="ruleForm.userName" disabled />
    </el-form-item>
    <el-form-item label="留言内容：">
      <el-input type="textarea" :rows="5" v-model="ruleForm.content" disabled />
      <div class="message-photos" v-if="ruleForm.picture && ruleForm.picture.length > 0">
        <div class="photo" v-for="(item, index) in ruleForm.picture" :key="index">
          <img :src="item" />
        </div>
      </div>
    </el-form-item>
    <el-form-item label="回复内容：" v-if="item.status == 1" prop="replyContent">
      <el-input type="textarea" :rows="5" v-model="ruleForm.replyContent" />
    </el-form-item>
    <el-form-item label="回复人：" v-if="item.status == 0">
      <el-input v-model="ruleForm.queryVo.userName" disabled />
    </el-form-item>
    <el-form-item label="回复内容：" v-if="item.status == 0">
      <el-input type="textarea" :rows="5" v-model="ruleForm.queryVo.content" disabled />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitForm(ruleFormRef)">{{ item.status == 0 ? '确定': '回复'}}</el-button>
      <el-button @click="resetForm(ruleFormRef)">取消</el-button>
    </el-form-item>
  </el-form>
</template>

<script setup>
import { reactive, ref, defineEmits, defineProps, onMounted } from 'vue'
import { selectById, update } from '@/api/leaveWord'

const ruleForm = ref({
  queryVo: {}
})

const getDetailById = async () => {
  const { id } = props.item
  const {data} = await selectById({ id })

  ruleForm.value = {...data}
  // , picture: [
  //           "https://lw-images.oss-cn-shanghai.aliyuncs.com/tuancan/20220629/7603c5ea-358e-488d-abc3-9f29342250ac.jpg",
  //           "https://lw-images.oss-cn-shanghai.aliyuncs.com/tuancan/20220629/7603c5ea-358e-488d-abc3-9f29342250ac.jpg"
  //       ]
}

onMounted(() => {
  getDetailById()
})

const props = defineProps({
  item: Object
})

const em = defineEmits(['closeAndRefresh'])

const ruleFormRef = ref()

const rules = reactive({
  replyContent: [
    { required: true, message: '请输入回复内容', trigger: 'blur' },
  ]
})

const submitForm = async (formEl) => {
  if (props.item.status == 0) {
    em('closeAndRefresh', true)
    return 
  }
  if (!formEl) return
  await formEl.validate()
  const {id, replyContent: content} = ruleForm.value
  const {message} = await update({ id, content })
  if (message == 'OK') {
    em('closeAndRefresh', true)
  }
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
  em('closeAndRefresh', false)
}

</script>
