<template>
  <div class="login-panel">
    <div class="title">
      <h3 class="name">请输入您想要找回密码的账号</h3>
      <div class="r-text">
        已有账户，<a href="#/login">登录</a>
      </div>
    </div>
    <div class="cont">
      <div class="login__form">
        <el-form class="form__cont" ref="ruleFormRef" :model="ruleForm" :rules="rules">
          <el-form-item prop="name">
            <el-input v-model="ruleForm.name" placeholder="请输入用户名"/>
          </el-form-item>
          <el-form-item class="yzm-form-item" prop="yzm">
            <el-input v-model="ruleForm.code" placeholder="请输入验证码">
              <template #append>
                <div class="yzm-code">
                  <img :src="codeImg"/>
                  <div class="refresh-btn" @click="getCodeImg"></div>
                </div>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item class="button" style="margin-top: 50px">
            <el-button type="primary" round @click="doNext(ruleFormRef)">下一步</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import { getVerifyCode, forgetPwd, send } from '@/api/systemUser'

const ruleFormRef = ref()

const ruleForm = reactive({
  name: '',
  code: ''
})
const rules = reactive({
  name: [
    { required: true, message: '请输入用户名', trigger: 'blur' },
  ],
  code: [
    { required: true, message: '请输入验证码', trigger: 'blur' },
  ]
})

const router = useRouter()

const doNext = async (formEl) => {
  if (!formEl) return
  await formEl.validate()
  let {data, message} = await forgetPwd(ruleForm)
  if (message == 'OK') {
    const {email, userName} = data
    window.sessionStorage.setItem('email', email)
    window.sessionStorage.setItem('userName', userName)
    const res = await send({email, userName})
    if (res.message == 'OK') {
      ElMessage({
        message: '验证码已发送至您的邮箱，请查收！',
        type: 'success',
      })
      router.push('/getback')
    }
  } else {
    getCodeImg()
  }
}

const codeImg = ref(null)

const getCodeImg = async () => {
  const res = await getVerifyCode({responseType: 'arraybuffer'})
  const img = 'data:image/png;base64,' + btoa(
    new Uint8Array(res).reduce((data, byte) => data + String.fromCharCode(byte), '')
  )
  codeImg.value = img
}

onMounted(async () => {
  getCodeImg()
})



</script>
