import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'

const service = axios.create({
  baseURL: '/api',
  //baseURL: 'http://127.0.0.1:18887/api',
  timeout: 60 * 1000
})

service.interceptors.request.use(config => {
  config.headers['x-request-with'] = 'XMLHttpRequest'
  if (config.headers['Content-Type'] === 'multipart/form-data') {
    return config
  }
  config.headers['Content-Type'] = 'application/json; charset=UTF-8'
  const token = window.localStorage.getItem('token')
  if (!token && !config.url.includes('/systemUser')) {
    window.location.href='/#/login'
  }
  config.headers.Authorization = `Bearer ${token}`
  return config
}, error => {
  return null
})

service.interceptors.response.use(
  response => {
    let res = response.data
    if (!(res.status == '200' || response.config.url.includes('/getVerifyCode'))) {
      ElMessage.error(res.message)
    }
    return res
  },
  error => {
    if (error.response.status == 401) {
      window.location.href='/#/login'
    }
    return null
  }
)

export default service
