<template>
  <div class="chk-edit-list-item">
    <div class="item">
      <div class="tit">关联单词：</div>
      <div class="con">【{{ showNameById(item.relatedWordId, selectAllWordData) }}】</div>
    </div>
    <div class="item">
      <div class="tit">游戏方式：</div>
      <div class="con">
        <el-select v-model="item.gameType" style="width: 126px; margin-right: 10px;"
          placeholder="请选择">
          <el-option v-for="item in GameType" :key="item.value"
            :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="item" v-if="item.gameType == 0">
      <div class="tit">正确图片：</div>
      <div class="con">
        <el-upload
          v-model:file-list="item.fileList"
          action="/api/word/upload"
          list-type="picture-card"
          :headers="headers"
          :on-success="handleUploadSuccess"
          :before-upload="beforeUpload"
          :limit="3"
        >
          <el-icon><Plus /></el-icon>
        </el-upload>
      </div>
    </div>
    <div class="item" v-if="item.gameType == 0">
      <div class="tit">错误图片：</div>
      <div class="con">
        <el-upload
          v-model:file-list="item.fileList2"
          action="/api/word/upload"
          list-type="picture-card"
          :headers="headers"
          :on-success="handleUploadSuccess2"
          :before-upload="beforeUpload2"
          :limit="3"
        >
          <el-icon><Plus /></el-icon>
        </el-upload>
      </div>
    </div>
    <div class="close-btn" @click="deleteItem"><el-icon><Close /></el-icon></div>
  </div>
</template>

<script setup>
import { ref, reactive, defineProps, defineEmits, onMounted } from 'vue'
import { selectByType } from '@/api/righteousField'
import { Close, Plus } from '@element-plus/icons-vue'
import { GameType } from '@/contant'

const props = defineProps({
  item: Object,
  selectAllWordData: Array
})

const em = defineEmits(['deleteItem'])
const deleteItem = () => {
  em('deleteItem')
}

const showNameById = (value, list) => {
  if (!value) return ''
  const cur = list.find(item => item.id == value)
  if (!cur) return ''
  return cur.name
}

onMounted(() => {
  
})

const headers = ref({Authorization: `Bearer ${window.localStorage.getItem('token')}`})
const handleUploadSuccess = (response, uploadFile) => {
  // fileList.value.push({
  //   name: uploadFile.name,
  //   url: response.data
  // })
}
const beforeUpload = (rawFile) => {
  if (props.item.fileList.length >= 3) {
    return false
  }
  return true
}

const handleUploadSuccess2 = (response, uploadFile) => {
  // fileList.value.push({
  //   name: uploadFile.name,
  //   url: response.data
  // })
}
const beforeUpload2 = (rawFile) => {
  if (props.item.fileList2.length >= 3) {
    return false
  }
  return true
}

</script>
