<template>
  <Breadcrumb icon="icon-video" :list="['用户管理', '学习排行']"/>
  <div class="table-tools2">
    <el-form :inline="true" :model="searchForm" ref="ruleFormRef">
      <el-form-item label="日期：" prop="time">
        <el-date-picker v-model="searchForm.time" value-format="YYYY-MM-DD" type="date" placeholder="选择日期"/>
      </el-form-item>
      <el-form-item class="form-item-buttons">
        <el-button type="primary" @click="getList({page: 1})">查询</el-button>
      </el-form-item>
    </el-form>
  </div>
  <div class="table">
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column prop="sort" label="排行" />
      <el-table-column prop="code" label="用户编号" />
      <el-table-column prop="nickname" label="昵称" />
      <el-table-column prop="phone" label="手机号" />
      <el-table-column prop="nowStudyRighteousNumber" label="今日探索义场数" />
      <el-table-column prop="sort" label="探索度最高义场">
        <template #default="scope">
          {{ scope.row.oneRighteous }}/{{ scope.row.twoRighteous }}/{{ scope.row.threeRighteous }}
        </template>
      </el-table-column>
      <el-table-column prop="travelNumber" label="旅行次数" />
      <el-table-column prop="studyRighteousNumber" label="探索义场数" />
      <el-table-column prop="studyWordNumber" label="探索单词数" />
      <el-table-column prop="righteousNumber" label="解锁义场总数">
        <template #default="scope">
          {{ scope.row.keyRighteousNumber }}/{{ scope.row.righteousNumber }}
        </template>
      </el-table-column>
    </el-table>
    <div class="page">
      <el-pagination
        v-model:currentPage="searchForm.page"
        :page-size="searchForm.pageSize"
        layout="total, prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script setup>

import { reactive, ref, onMounted } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { selectStudyRanking } from '@/api/user'

const ruleFormRef = ref()

const searchForm = reactive({
  page: 1,
  pageSize: 10,
  time: ''
})

const tableData = ref([])
const total = ref(0)

const getList = async (params) => {
  Object.assign(searchForm, params)
  const {data} = await selectStudyRanking({
    ...searchForm
  })
  tableData.value = data.items
  total.value = data.total
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
  getList({page: 1})
}

onMounted(() => {
  getList()
})

const handleSizeChange = (size) => {
  searchForm.pageSize = size
  getList()
}
const handleCurrentChange = (page) => {
  searchForm.page = page
  getList()
}

</script>
