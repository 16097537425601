import Breadcrumb from './breadcrumb'
import TEditor from './TEditor'
import ChkYc from './ChkYc'
import ChkHasYc from './ChkHasYc'
import WordsRelationExtension from './WordsRelationExtension'
import WordMorphological from './WordMorphological'

const list = [
  Breadcrumb,
  TEditor,
  ChkYc,
  ChkHasYc,
  WordsRelationExtension,
  WordMorphological
]

export default {
  install(app) {
    for (const component of list) {
      app.use(component)
    }
  }
}
