<template>
  <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="100px" status-icon>
    <el-form-item label="购买内容：" prop="buyContent">
      <el-select v-model="ruleForm.buyContent" clearable placeholder="请选择">
        <el-option v-for="item in ChargeBuyContent" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
    </el-form-item>
    <el-form-item label="有效时间：" prop="validTime">
      <el-select v-model="ruleForm.validTime" clearable placeholder="请选择">
        <el-option v-for="item in ChargeValidTime" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
    </el-form-item>
    <el-form-item label="收费标准：" prop="money">
      <el-input v-model="ruleForm.money" />
    </el-form-item>
    <el-form-item label="规则说明：" prop="rule">
      <el-input type="textarea" rows="5" v-model="ruleForm.rule" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitForm(ruleFormRef)">确认</el-button>
      <el-button @click="resetForm(ruleFormRef)">取消</el-button>
    </el-form-item>
  </el-form>
</template>

<script setup>
import { reactive, ref, defineEmits } from 'vue'
import { add } from '@/api/chargeProject'
import {ChargeValidTime, ChargeBuyContent} from '@/contant'

const em = defineEmits(['closeAndRefresh'])

const ruleFormRef = ref()

const ruleForm = reactive({
  isDisable: false,
})

const validateMoney = (rule, value, callback) => {
  const reg = /^(0|[1-9][0-9]*)(\.\d+)?$/
  if (!(reg.test(+value) && value > 0)) {
    callback(new Error('请输入正数'))
  } else {
    callback()
  }
}
const rules = reactive({
  money: [{ validator: validateMoney, trigger: 'blur' }]
})

const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate()
  const {message} = await add(ruleForm)
  if (message == 'OK') {
    em('closeAndRefresh', true)
  }
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
  em('closeAndRefresh', false)
}

</script>
