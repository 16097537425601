<template>
  <Breadcrumb icon="icon-video" :list="['内容管理', '义场管理']"/>
  <div class="table-tools">
    <el-form :inline="true" :model="searchForm" label-width="90px" ref="ruleFormRef">
      <el-form-item label="一级义场：" prop="levelOne">
        <el-select v-model="searchForm.levelOne" filterable placeholder="请选择" @change="changeLevelOne">
          <el-option v-for="item in selectList[0]" :key="item.id" :label="item.name" :value="item.id"/>
        </el-select>
      </el-form-item>
      <el-form-item label="二级义场：" prop="levelTwo" v-if="searchForm.type > 0">
        <el-select v-model="searchForm.levelTwo" filterable placeholder="请选择" @change="changeLevelTwo">
          <el-option v-for="item in selectList[1]" :key="item.id" :label="item.name" :value="item.id"/>
        </el-select>
      </el-form-item>
      <el-form-item label="三级义场：" prop="levelThree" v-if="searchForm.type > 1">
        <el-select v-model="searchForm.levelThree" filterable placeholder="请选择">
          <el-option v-for="item in selectList[2]" :key="item.id" :label="item.name" :value="item.id"/>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item-buttons">
        <el-button type="primary" @click="getList({page: 1})">查询</el-button>
        <el-button type="default" @click="resetForm(ruleFormRef)">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="buttons">
      <template v-for="item in tabs" :key="item.value">
        <el-button class="button" type="primary" :key="item.value" v-if="searchForm.type == item.value" @click="addItem">
          新增{{ item.label }}
        </el-button>
      </template>
    </div>
  </div>
  <div class="yc-tabs">
    <div class="title">
      <div class="item" v-for="tab in tabs" :key="tab.value" :class="{'active': searchForm.type == tab.value}" @click="changeTab(tab.value)">
        {{ tab.label }}
      </div>
    </div>
    <div class="table">
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="sort" label="排序" width="55" />
        <el-table-column prop="levelOneName" label="一级义场" />
        <el-table-column prop="levelTwoName" v-if="searchForm.type > 0" label="二级义场" />
        <el-table-column prop="levelTwoNumber" v-else label="二级义场数" />
        <el-table-column prop="levelThreeName"  v-if="searchForm.type > 1" label="三级义场" />
        <el-table-column prop="levelThreeNumber" v-else label="三级义场数" />
        <el-table-column prop="wordNumber" label="义场内单词数" />
        <el-table-column prop="keyUserNumber" label="已解锁次数" />
        <el-table-column prop="updateTime" label="最近操作时间" width="154px"/>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button link type="primary" size="small" @click="editItem(scope.row)">编辑</el-button>
            <el-button link type="primary" size="small" @click="doDeleteItem(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page">
        <el-pagination
          v-model:currentPage="searchForm.page"
          :page-size="searchForm.pageSize"
          layout="total, prev, pager, next"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <el-drawer v-model="addVisible" title="新增义场" direction="rtl" size="40%">
      <AddForm v-if="addVisible" @closeAndRefresh="closeAndRefresh" :type="searchForm.type" :selectList="selectList"/>
    </el-drawer>
    <el-drawer v-model="editVisible" title="编辑义场" direction="rtl" size="40%">
      <EditForm v-if="editVisible" @closeAndRefresh="closeAndRefresh" :item="currentItem" :type="searchForm.type" :selectList="selectList"/>
    </el-drawer>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { selectByPage, selectByType, deleteRighteousField } from '@/api/righteousField'
import AddForm from './Add'
import EditForm from './Edit'

const ruleFormRef = ref()

const searchForm = reactive({
  page: 1,
  pageSize: 10,
  levelOne: '',
  levelTwo: '',
  levelThree: '',
  type: 0
})

const tabs = reactive([
  {label: '一级义场', value: 0},
  {label: '二级义场', value: 1},
  {label: '三级义场', value: 2}
])
const changeTab = (tab) => {
  searchForm.type = tab
  getList()
}

const tableData = ref([])
const total = ref(0)

const getList = async (params) => {
  Object.assign(searchForm, params)
  const {data} = await selectByPage({
    ...searchForm
  })
  data.items.map(item => {
    switch (searchForm.type) {
      case 0:
        item.levelOneName = item.name
        break;
      case 1:
        item.levelTwoName = item.name
        break;
      case 2:
        item.levelThreeName = item.name
        break;
      default:
        break;
    }
  })
  tableData.value = data.items
  total.value = data.total
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
  getList({page: 1})
}

onMounted(() => {
  getList()
  getSelectByType({type: 0})
})

const handleSizeChange = (size) => {
  searchForm.pageSize = size
  getList()
}

const handleCurrentChange = (page) => {
  searchForm.page = page
  getList()
}

const doDeleteItem = async (item) => {
  await ElMessageBox.confirm(
    '确定要删除吗?',
    '友情提示'
  )
  const {id} = item
  await deleteRighteousField({id})
  getList()
}

const selectList = reactive([[], [], []])
const getSelectByType = async (params) => {
  const {data} = await selectByType(params)
  selectList[params.type] = data
} 

const changeLevelOne = (id) => {
  getSelectByType({type: 1, id})
  selectList[1] = []
  selectList[2] = []
}
const changeLevelTwo = (id) => {
  getSelectByType({type: 2, id})
  selectList[2] = []
}

const addVisible = ref(false)
const addItem = () => {
  addVisible.value = true
}

const closeAndRefresh = (refresh, visible) => {
  if (!visible) {
    addVisible.value = false
    editVisible.value = false
  }
  if (refresh) {
    getList()
    if (searchForm.type == 0) {
      getSelectByType({type: 0})
    }
  }
}

const editVisible = ref(false)
const currentItem = ref({})
const editItem = (item) => {
  currentItem.value = item
  editVisible.value = true
}

</script>
