<template>
  <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="100px" status-icon>
    <el-form-item label="标题：" prop="title">
      <el-input v-model="ruleForm.title" />
    </el-form-item>
    <el-form-item label="主图：" prop="photo">
      <el-upload
        class="banner-uploader"
        action="/api/word/upload"
        :headers="headers"
        :show-file-list="false"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload"
      >
        <img v-if="ruleForm.photo" :src="ruleForm.photo" class="banner-img" />
        <el-icon v-else class="banner-uploader-icon"><Plus /></el-icon>
      </el-upload>
    </el-form-item>
    <el-form-item label="正文：" prop="content">
      <TEditor class="agreement-teditor" v-model="ruleForm.content" />
    </el-form-item>
    <el-form-item label="排序：" prop="sort">
      <el-input v-model="ruleForm.sort" />
    </el-form-item>
    <el-form-item label="是否启用：" prop="isDisable">
      <el-radio-group v-model="ruleForm.isDisable">
        <el-radio :label="false">启用</el-radio>
        <el-radio :label="true">禁用</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitForm(ruleFormRef)">确认</el-button>
      <el-button @click="resetForm(ruleFormRef)">取消</el-button>
    </el-form-item>
  </el-form>
</template>

<script setup>
import { reactive, ref, defineEmits } from 'vue'
import { ElMessage } from 'element-plus'
import { Plus } from '@element-plus/icons-vue'
import { add } from '@/api/banner'

const em = defineEmits(['closeAndRefresh'])

const ruleFormRef = ref()

const ruleForm = reactive({
  isDisable: false,
})

const rules = reactive({
  title: [
    { required: true, message: '请输入标题', trigger: 'blur' },
  ],
  photo: [
    { required: true, message: '请输入上传主图', trigger: 'change' },
  ],
  content: [
    { required: true, message: '请输入正文', trigger: 'blur' },
  ]
})

const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate()
  ruleForm.sort = (+ruleForm.sort)
  const {message} = await add(ruleForm)
  if (message == 'OK') {
    em('closeAndRefresh', true)
  }
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
  em('closeAndRefresh', false)
}

const headers = ref({Authorization: `Bearer ${window.localStorage.getItem('token')}`})

const handleAvatarSuccess = (response,uploadFile) => {
  ruleForm.photo = response.data
}

const beforeAvatarUpload = (rawFile) => {
  return true
}

</script>
