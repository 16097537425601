<template>
  <el-scrollbar height="100%">
    <div class="title">后台管理系统</div>
    <el-menu
      :default-active="activePath"
      class="el-menu-vertical-demo"
      :collapse="$store.getters.isCollapse"
      :unique-opened="true"
      :router="true"
    >
      <el-menu-item index="/mgr/dashboard">
        <el-icon>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="64 64 896 896" width="18" height="18" style="border-color: rgba(0,0,0,0);border-width: bpx;border-style: undefined" filter="none">
            <g>
            <path d="M946.5 505L534.6 93.4a31.93 31.93 0 0 0-45.2 0L77.5 505c-12 12-18.8 28.3-18.8 45.3 0 35.3 28.7 64 64 64h43.4V908c0 17.7 14.3 32 32 32H448V716h112v224h265.9c17.7 0 32-14.3 32-32V614.3h43.4c17 0 33.3-6.7 45.3-18.8 24.9-25 24.9-65.5-.1-90.5z"></path>
            </g>
          </svg>
        </el-icon>
        <template #title>首页</template>
      </el-menu-item>
      <el-sub-menu index="/mgr/content">
        <template #title>
          <el-icon>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" style="border-color: rgba(0,0,0,0);border-width: bpx;border-style: undefined" filter="none">
              <g>
              <path d="M2 6H0v5h.01L0 20c0 1.1.9 2 2 2h18v-2H2V6zm20-2h-8l-2-2H6c-1.1 0-1.99.9-1.99 2L4 16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zM7 15l4.5-6 3.5 4.51 2.5-3.01L21 15H7z"></path>
              </g>
            </svg>
          </el-icon>
          <span>内容管理</span>
        </template>
        <el-menu-item index="/mgr/content/yc">义场管理</el-menu-item>
        <el-menu-item index="/mgr/content/chk">词汇库</el-menu-item>
        <el-menu-item index="/mgr/content/banner">banner管理</el-menu-item>
        <el-menu-item index="/mgr/content/ck">词库管理</el-menu-item>
      </el-sub-menu>
      <el-sub-menu index="/mgr/user">
        <template #title>
          <el-icon>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="18" height="18" style="border-color: rgba(0,0,0,0);border-width: bpx;border-style: undefined" filter="none">
              <g>
              <path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path>
              </g>
            </svg>
          </el-icon>
          <span>用户管理</span>
        </template>
        <el-menu-item index="/mgr/user/list">用户管理</el-menu-item>
        <el-menu-item index="/mgr/user/study">学习排行</el-menu-item>
        <el-menu-item index="/mgr/user/message">留言回复</el-menu-item>
        <el-menu-item index="/mgr/user/order">订单总览</el-menu-item>
      </el-sub-menu>
      <el-sub-menu index="/mgr/system">
        <template #title>
          <el-icon>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="18" height="18" style="border-color: rgba(0,0,0,0);border-width: bpx;border-style: undefined" filter="none">
              <g>
              <path d="M149.333 216v80c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24v-80c0-13.255 10.745-24 24-24h101.333c13.255 0 24 10.745 24 24zM0 376v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H24c-13.255 0-24 10.745-24 24zM125.333 32H24C10.745 32 0 42.745 0 56v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24zm80 448H488c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24zm-24-424v80c0 13.255 10.745 24 24 24H488c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24zm24 264H488c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24z"></path>
              </g>
            </svg>
          </el-icon>
          <span>系统管理</span>
        </template>
        <el-menu-item index="/mgr/system/user">系统用户管理</el-menu-item>
        <el-menu-item index="/mgr/system/agreement">服务协议配置</el-menu-item>
        <el-menu-item index="/mgr/system/log">操作日志</el-menu-item>
      </el-sub-menu>
    </el-menu>
  </el-scrollbar>
</template>

<script setup>
import { ref } from 'vue'
import { useRoute } from 'vue-router'

const isCollapse = ref(true)
const route = useRoute()
const activePath = ref(route.path)

</script>