<template>
  <Breadcrumb icon="icon-video" :list="['内容管理', '词库管理']"/>
  <div class="table-tools2">
    <div class="buttons">
      <el-button class="button" type="primary" @click="addItem">新增词库</el-button>
    </div>
    <el-form :inline="true" :model="searchForm" ref="ruleFormRef">
      <el-form-item prop="name">
        <el-input v-model="searchForm.name" placeholder="请输入词库名称查询"/>
      </el-form-item>
      <el-form-item class="form-item-buttons">
        <el-button type="primary" @click="getList({page: 1})">查询</el-button>
      </el-form-item>
    </el-form>
  </div>
  <div class="table">
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column type="index" width="54" label="序号" />
      <el-table-column prop="name" label="词库名称" />
      <el-table-column prop="sort" label="排序">
        <template #default="scope">
          <el-input size="small" style="width: 44px;" v-model="scope.row.sort" @change="changeSort(scope.row)" />
        </template>
      </el-table-column>
      <el-table-column prop="isDisable" label="是否启用">
        <template #default="scope">
          <el-switch :active-value="false" :inactive-value="true" v-model="scope.row.isDisable" @change="changeDisable(scope.row)"/>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="120">
        <template #default="scope">
          <el-button link type="primary" size="small" @click="editItem(scope.row)">编辑</el-button>
          <el-button link type="primary" size="small" @click="doDeleteItem(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page">
      <el-pagination
        v-model:currentPage="searchForm.page"
        :page-size="searchForm.pageSize"
        layout="total, prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-drawer v-model="addVisible" title="新增词库" direction="rtl" size="30%">
      <AddForm @closeAndRefresh="closeAndRefresh"/>
    </el-drawer>
    <el-drawer v-model="editVisible" title="编辑词库" direction="rtl" size="30%">
      <EditForm v-if="editVisible" @closeAndRefresh="closeAndRefresh" :item="currentItem"/>
    </el-drawer>
  </div>
</template>

<script setup>

import { reactive, ref, onMounted } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { selectByPage, disableLexicon, deleteLexicon, sortLexicon } from '@/api/lexicon'
import AddForm from './Add'
import EditForm from './Edit'

const ruleFormRef = ref()

const searchForm = reactive({
  page: 1,
  pageSize: 10,
  userName: '',
  name: '',
  email: '',
  isDisable: 2,
  createTime: ''
})

const tableData = ref([])
const total = ref(0)

const getList = async (params) => {
  Object.assign(searchForm, params)
  let createStartTime, createEndTime
  if (searchForm.createTime && searchForm.createTime.length > 1) {
    createStartTime = searchForm.createTime[0]
    createEndTime = searchForm.createTime[1]
  }
  const {data} = await selectByPage({
    createStartTime,
    createEndTime,
    ...searchForm
  })
  tableData.value = data.items
  total.value = data.total
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
  getList({page: 1})
}

onMounted(() => {
  getList()
})

const handleSizeChange = (size) => {
  searchForm.pageSize = size
  getList()
}
const handleCurrentChange = (page) => {
  searchForm.page = page
  getList()
}

const changeDisable = async (item) => {
  const {id, isDisable} = item
  await disableLexicon({id, isDisable})
  getList()
}

const doDeleteItem = async (item) => {
  await ElMessageBox.confirm(
    '确定要删除吗?',
    '友情提示'
  )
  const {id} = item
  await deleteLexicon({id})
  getList()
}

const addVisible = ref(false)
const addItem = () => {
  addVisible.value = true
}

const closeAndRefresh = (refresh) => {
  addVisible.value = false
  editVisible.value = false
  if (refresh) {
    getList()
  }
}

const editVisible = ref(false)
const currentItem = ref({})
const editItem = (item) => {
  currentItem.value = item
  editVisible.value = true
}

const changeSort = async (item) => {
  const {id, sort} = item
  const reg = /^([1-9][0-9]*)(\d+)?$/
  if (reg.test(+sort)) {
    const {message} = await sortLexicon({id, sort})
    if (message == 'OK') {
      getList()
    }
  } else {
    ElMessage.error('请输入正整数')
  }
}
</script>
