import request from '@/utils/request'

/**
 * 获取最新的服务协议配置
 * @returns 
 */
export function selectOne() {
  return request({
    url: `/config/selectOne`,
    method: 'get'
  })
}

/**
 * 编辑
 * @param {value} data 
 * @returns 
 */
 export function update(data) {
  return request({
    url: `/config/update`,
    method: 'post',
    data
  })
}
