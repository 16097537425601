<template>
  <Breadcrumb icon="icon-video" :list="['用户管理', '用户管理']"/>
  <div class="table-tools">
    <el-form :inline="true" :model="searchForm" label-width="110px" ref="ruleFormRef">
      <el-form-item label="手机号：" prop="phone">
        <el-input v-model="searchForm.phone" />
      </el-form-item>
      <el-form-item label="昵称：" prop="nickname">
        <el-input v-model="searchForm.nickname" />
      </el-form-item>
      <el-form-item label="编号：" prop="code">
        <el-input v-model="searchForm.code" />
      </el-form-item>
      <el-form-item label="注册日期：" prop="createTime">
        <el-date-picker
          value-format="YYYY-MM-DD"
          v-model="searchForm.createTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </el-form-item>
      <el-form-item label="会员到期日期：" prop="membershipDueDate">
        <el-date-picker
          value-format="YYYY-MM-DD"
          v-model="searchForm.membershipDueDate"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </el-form-item>
      <el-form-item label="是否会员：" prop="isMember">
        <el-radio-group v-model="searchForm.isMember">
          <el-radio-button :label="null">全部</el-radio-button>
          <el-radio-button :label="1">会员</el-radio-button>
          <el-radio-button :label="0">非会员</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="常居地：" prop="operator">
        <el-cascader v-model="addressCascader" :props="{value: 'id', label: 'name'}" :options="options" @change="addressChange" clearable></el-cascader>
      </el-form-item>
      <el-form-item class="form-item-buttons">
        <el-button type="primary" @click="getList({page: 1})">查询</el-button>
        <el-button type="default" @click="resetForm(ruleFormRef)">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
  <div class="table">
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column prop="code" label="用户编号" />
      <el-table-column prop="nickname" label="昵称" />
      <el-table-column prop="phone" label="手机号" />
      <el-table-column prop="membershipDueDate" label="会员到期日期" />
      <el-table-column prop="friends" label="好友人数" />
      <el-table-column prop="money" label="消费总额(元)" />
      <el-table-column prop="inviter" label="邀请人" />
      <el-table-column prop="createTime" label="注册时间" />
      <el-table-column label="操作">
        <template #default="scope">
          <el-button link type="primary" size="small" @click="showDetail(scope.row)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page">
      <el-pagination
        v-model:currentPage="searchForm.page"
        :page-size="searchForm.pageSize"
        layout="total, prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog v-model="dialogVisible" title="用户详情" width="90%" top="3vh">
      <Detail v-if="dialogVisible" :item="currentItem"/>
    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { selectByPage, regionTree } from '@/api/user'
import Detail from './Detail'
import { regionData, CodeToText } from 'element-china-area-data'

const ruleFormRef = ref()

const searchForm = reactive({
  page: 1,
  pageSize: 10,
  code: '',
  isMember: null,
  nickname: '',
  phone: '',
  createTime: '',
  province: '',
  city: '',
  district: '',
  membershipDueDate: ''
})

const tableData = ref([])
const total = ref(0)

const getList = async (params) => {
  Object.assign(searchForm, params)
  let createStartTime, createEndTime
  if (searchForm.createTime && searchForm.createTime.length > 1) {
    createStartTime = searchForm.createTime[0]
    createEndTime = searchForm.createTime[1]
  }
  let membershipDueStartDate, membershipDueEndDate
  if (searchForm.membershipDueDate && searchForm.membershipDueDate.length > 1) {
    membershipDueStartDate = searchForm.membershipDueDate[0]
    membershipDueEndDate = searchForm.membershipDueDate[1]
  }
  const {data} = await selectByPage({
    createStartTime,
    createEndTime,
    membershipDueStartDate,
    membershipDueEndDate,
    ...searchForm
  })
  tableData.value = data.items
  total.value = data.total
}

const resetForm = (formEl) => {
  if (!formEl) return
  addressCascader.value = ''
  searchForm.province = ''
  searchForm.city = ''
  searchForm.district = ''
  formEl.resetFields()
  getList({page: 1})
}

const options = ref([])
const getRegionTree = async () => {
  const {data} = await regionTree({id: '100000'})
  options.value = data
}

onMounted(() => {
  getRegionTree()
  getList()
})

const handleSizeChange = (size) => {
  searchForm.pageSize = size
  getList()
}
const handleCurrentChange = (page) => {
  searchForm.page = page
  getList()
}

const currentItem = ref({})
const dialogVisible = ref(false)
const showDetail = (item) => {
  currentItem.value = item
  dialogVisible.value = true
}

// const selectedOptions = ref([])
// const options = ref([])
// options.value = regionData
const addressCascader = ref()
const addressChange = (arr) => {
  if (arr && arr.length > 0) {
    const province = options.value.find(item => item.id == arr[0])
    const city = province.children.find(item => item.id == arr[1])
    const district = city.children.find(item => item.id == arr[2])

    searchForm.province = province.name
    searchForm.city = city.name
    searchForm.district = district.name
  } else {
    addressCascader.value = ''
    searchForm.province = ''
    searchForm.city = ''
    searchForm.district = ''
  }
}

</script>
