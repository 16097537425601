<template>
  <Breadcrumb icon="icon-video" :list="['用户管理', '订单总览']"/>
  <div class="order-numbers">
    <div class="item">订单总金额：{{ money }}元</div>
    <div class="item">付费用户数：{{ userNumber }}</div>
  </div>
  <div class="table-tools">
    <el-form :inline="true" :model="searchForm" label-width="90px" ref="ruleFormRef">
      <el-form-item label="手机号：" prop="phone">
        <el-input v-model="searchForm.phone" />
      </el-form-item>
      <el-form-item label="昵称：" prop="nickname">
        <el-input v-model="searchForm.nickname" />
      </el-form-item>
      <el-form-item label="用户编号：" prop="userCode">
        <el-input v-model="searchForm.userCode" />
      </el-form-item>
      <el-form-item label="订单编号：" prop="code">
        <el-input v-model="searchForm.code" />
      </el-form-item>
      <el-form-item label="购买内容：" prop="buyContent">
        <el-select v-model="searchForm.buyContent" clearable placeholder="请选择">
          <el-option v-for="item in ChargeBuyContent" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="购买日期：" prop="buyTime">
        <el-date-picker
          value-format="YYYY-MM-DD"
          v-model="searchForm.buyTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </el-form-item>
      <el-form-item class="form-item-buttons">
        <el-button type="primary" @click="getList({page: 1})">查询</el-button>
        <el-button type="default" @click="resetForm(ruleFormRef)">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="buttons">
      <el-button class="button" type="primary" @click="showCharge">收费标准</el-button>
    </div>
  </div>
  <div class="table">
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column prop="code" label="订单编号" />
      <el-table-column prop="userCode" label="用户编号" />
      <el-table-column prop="nickname" label="昵称">
        <template #default="scope">
          <div>{{ scope.row.nickname }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="手机号" />
      <el-table-column prop="buyContent" label="购买内容">
        <template #default="scope">
          {{ getContantLabelByValue(scope.row.buyContent, ChargeBuyContent) }}
        </template>
      </el-table-column>
      <el-table-column prop="number" label="数量" />
      <el-table-column prop="money" label="消费总额(元)" />
      <el-table-column prop="buyTime" label="购买时间" />
      <el-table-column label="操作">
        <template #default="scope">
          <el-button link type="primary" size="small" @click="orderDetail(scope.row)">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page">
      <el-pagination
        v-model:currentPage="searchForm.page"
        :page-size="searchForm.pageSize"
        layout="total, prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-drawer v-model="detailVisible" title="订单详情" direction="rtl" size="40%">
      <Detail @closeAndRefresh="closeAndRefresh" :item="currentItem"/>
    </el-drawer>

    <el-dialog draggable v-model="dialogVisible" title="收费标准" width="90%" top="3vh">
      <Charge v-if="dialogVisible" />
    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { selectByPage } from '@/api/order'
import Detail from './Detail'
import Charge from './charge'
import {ChargeValidTime, ChargeBuyContent} from '@/contant'

const ruleFormRef = ref()

const searchForm = reactive({
  page: 1,
  pageSize: 10,
  buyTime: ''
})

const tableData = ref([])
const total = ref(0)
const money = ref(0)
const userNumber = ref(0)

const getList = async (params) => {
  Object.assign(searchForm, params)
  let buyStartTime, buyEndTime
  if (searchForm.buyTime && searchForm.buyTime.length > 1) {
    buyStartTime = searchForm.buyTime[0]
    buyEndTime = searchForm.buyTime[1]
  }
  const {data} = await selectByPage({
    buyStartTime,
    buyEndTime,
    ...searchForm
  })
  tableData.value = data.pageResult.items
  total.value = data.pageResult.total
  money.value = data.money
  userNumber.value = data.userNumber
}

const getContantLabelByValue = (value, list) => {
  if (value === '' || value < 0) return  '' 
  const item = list.find(item => item.value == value)
  if (!item) return ''
  return item.label
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
  getList({page: 1})
}

onMounted(() => {
  getList()
})

const handleSizeChange = (size) => {
  searchForm.pageSize = size
  getList()
}
const handleCurrentChange = (page) => {
  searchForm.page = page
  getList()
}

const currentItem = ref({})
const detailVisible = ref(false)
const orderDetail = (item) => {
  detailVisible.value = true
  currentItem.value = item
}

const closeAndRefresh = () => {
  detailVisible.value = false
}

const dialogVisible = ref(false)
const showCharge = () => {
  dialogVisible.value = true
}

</script>
