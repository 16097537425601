<template>
  <div class="agreement-teditor">
    <Breadcrumb icon="icon-video" :list="['系统管理', '服务协议配置']"/>
    <TEditor v-if="!isLoading" class="agreement-teditor" v-model="content" />
    <div class="buttons">
      <el-button @click="getData">取消</el-button>
      <el-button type="primary" @click="setData">保存</el-button>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted} from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { selectOne, update } from '@/api/config'

const content = ref('')

let detail = null
const getData = async () => {
  isLoading.value = true
  const {data} = await selectOne()
  detail = {...data} 
  content.value = data.value
  isLoading.value = false
}

const setData = async () => {
  const {id} = detail
  const {message} = await update({id, value: content.value})
  if (message == 'OK') {
    ElMessage({
      message: '保存成功！',
      type: 'success',
    })
  } else {
    ElMessage({
      message: '保存失败！',
      type: 'error',
    })
  }
}

const isLoading = ref(true)
onMounted(() => {
  getData()
})
</script>
