<template>
  <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="100px" status-icon>
     <el-form-item label="词库名称：" prop="name">
      <el-input v-model="ruleForm.name" />
    </el-form-item>
    <el-form-item label="排序：" prop="sort">
      <el-input v-model="ruleForm.sort" />
    </el-form-item>
    <el-form-item label="是否启用：" prop="isDisable">
      <el-radio-group v-model="ruleForm.isDisable">
        <el-radio :label="false">启用</el-radio>
        <el-radio :label="true">禁用</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitForm(ruleFormRef)">确定</el-button>
      <el-button @click="resetForm(ruleFormRef)">取消</el-button>
    </el-form-item>
  </el-form>
</template>

<script setup>
import { reactive, ref, defineEmits, defineProps, onMounted } from 'vue'
import { selectById, update } from '@/api/lexicon'

const ruleForm = ref({})

const getDetailById = async () => {
  const { id } = props.item
  const {data} = await selectById({ id })

  ruleForm.value = data
}

onMounted(() => {
  getDetailById()
})

const props = defineProps({
  item: Object
})

const em = defineEmits(['closeAndRefresh'])

const ruleFormRef = ref()

const rules = reactive({
  name: [
    { required: true, message: '请输入词库名称', trigger: 'blur' },
  ]
})

const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate()
  ruleForm.value.sort = (+ruleForm.value.sort)
  const {message} = await update(ruleForm.value)
  if (message == 'OK') {
    em('closeAndRefresh', true)
  }
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
  em('closeAndRefresh', false)
}

</script>
