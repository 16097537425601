<template>
  <header class="header">
    <div class="left">
      <div class="toggle-menu" @click="toggleCollapse"></div>
      <div class="name">后台管理系统</div>
    </div>
    <div class="right">
      <el-dropdown>
        <div class="user">
          <img src="@/assets/images/md-account_circle@1x.png" />
          <div class="username">{{ userinfo.name }}</div>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="showLog">修改用户信息</el-dropdown-item>
            <el-dropdown-item @click="editUser">操作日志</el-dropdown-item>
            <el-dropdown-item @click="exit">退出</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <el-drawer v-model="editUserVisible" title="操作日志" direction="rtl" size="780px">
      <SystemLog v-if="editUserVisible" :isDialog="true" :operator="userinfo.userName"/>
    </el-drawer>
    
    <el-dialog v-model="logVisible" title="编辑用户" width="580px" top="3vh">
      <EditForm v-if="logVisible" @closeAndRefresh="closeAndRefresh" :item="userinfo"/>
    </el-dialog>
  </header>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { getUserCurren } from '@/api/systemUser'
import EditForm from '@/views/mgr/system/user/Edit'
import SystemLog from '@/views/mgr/system/log'

const store = useStore()
const toggleCollapse = () => {
  store.commit('toggleCollapse')
}

const userinfo = ref({})
const getUserInfo = async () => {
  const {data} = await getUserCurren()
  userinfo.value = data
}

onMounted(() => {
  getUserInfo()
})

const closeAndRefresh = (refresh) => {
  logVisible.value = false
  if (refresh) {
    getUserInfo()
  }
}

const editUserVisible = ref(false)
const editUser = () => {
  editUserVisible.value = true
}

const logVisible = ref(false)
const showLog = () => {
  logVisible.value = true
}

const router = useRouter()
const exit = () => {
  window.localStorage.setItem('token', '')
  router.replace('/login')
}

</script>
