const ChargeValidTime = [
  {label: '一周', value: 0},
  {label: '一月', value: 1},
  {label: '一年', value: 2},
  {label: '永久有效', value: 3},
]
const ChargeBuyContent = [
  {label: '钥匙', value: 0},
  {label: '月卡', value: 1},
  {label: '年卡', value: 2}
]
const GameType = [
  {label: '图片选择', value: 0},
  {label: '听音选字母', value: 1}
]

export {
  ChargeValidTime,
  ChargeBuyContent,
  GameType
}