<template>
  <Breadcrumb icon="icon-home" :list="['首页']"/>
  <div class="dashboard">
    <div class="box info">
      <div class="user-info">
        <div class="photo"><img src="@/assets/images/md-account_circle@2x.png" /></div>
        <div class="text">
          <div class="name">{{ userinfo.name }} <span @click="showLog" class="icon icon-edit"></span></div>
          <div class="username">登录名：{{ userinfo.userName }}</div>
          <div class="status">账号状态：
            <el-tag v-if="!userinfo.isDisable">启用</el-tag>
            <el-tag v-else>未启用</el-tag>
            <div class="log" @click="editUser"><span class="icon icon-log"></span>操作日志</div>
          </div>
        </div>
      </div>
      <div class="numbers">
        <div class="item">
          <div class="icon icon01"></div>
          <div class="name">义场总数</div>
          <div class="num">{{ selectAllRighteousData }}</div>
        </div>
        <div class="item">
          <div class="icon icon02"></div>
          <div class="name">单词总数</div>
          <div class="num">{{ selectWordNumberData }}</div>
        </div>
        <div class="item">
          <div class="icon icon03"></div>
          <div class="name">现有用户数</div>
          <div class="num">{{ selectNumberByUserData }}</div>
        </div>
      </div>
    </div>
    <div class="boxs">
      <div class="box" style="margin-right: 10px;">
        <div class="title">
          <div class="name"><span class="icon icon-dashboard"></span>用户新增情况分析</div>
        </div>
        <div class="cont">
          <div id="selectUserAddNumberChart" class="echart"></div>
          <div class="echart-date">{{ selectUserAddNumberDate }}</div>
        </div>
      </div>
      <div class="box">
        <div class="title">
          <div class="name"><span class="icon icon-dashboard"></span>用户浏览量分析</div>
        </div>
        <div class="cont">
          <div id="selectUserViewNumberChart" class="echart"></div>
          <div class="echart-date">{{ selectUserViewNumberDate }}</div>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="title">
        <div class="name"><span class="icon icon-movie"></span>邀请用户排行榜</div>
      </div>
      <div class="cont">
        <div class="table">
          <el-table :data="tableData" stripe style="width: 100%">
            <el-table-column type="index" label="序号" width="55"/>
            <el-table-column prop="nickName" label="用户昵称" />
            <el-table-column prop="phone" label="手机号" />
            <el-table-column prop="number" label="邀请新用户数量" />
            <el-table-column prop="time" label="最近邀请时间" />
            <el-table-column prop="loginTime" label="最近操作时间" />
          </el-table>
        </div>
      </div>
    </div>
    <el-drawer v-model="editUserVisible" title="操作日志" direction="rtl" size="780px">
      <SystemLog v-if="editUserVisible" :isDialog="true" :operator="userinfo.userName"/>
    </el-drawer>
    
    <el-dialog v-model="logVisible" title="编辑用户" width="580px" top="3vh">
      <EditForm v-if="logVisible" @closeAndRefresh="closeAndRefresh" :item="userinfo"/>
    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted, inject, nextTick } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import * as moment from 'moment'
import { getUserCurren } from '@/api/systemUser'
import { selectInviterUser, selectNumberByUser, selectUserAddNumber, selectUserViewNumber } from '@/api/user'
import { selectAllRighteous } from '@/api/righteousField'
import { selectWordNumber } from '@/api/word'
import EditForm from '@/views/mgr/system/user/Edit'
import SystemLog from '@/views/mgr/system/log'

const ruleFormRef = ref()

const tableData = ref([])

const userinfo = ref({})
const getUserInfo = async () => {
  const {data} = await getUserCurren()
  userinfo.value = data
}

const getList = async () => {
  const {data} = await selectInviterUser()
  tableData.value = data
}

const selectNumberByUserData = ref('')
const getSelectNumberByUser = async () => {
  const {data} = await selectNumberByUser()
  selectNumberByUserData.value = data
}

const selectAllRighteousData = ref('')
const getSelectAllRighteous = async () => {
  const {data} = await selectAllRighteous()
  selectAllRighteousData.value = data
}

const selectWordNumberData = ref('')
const getSelectWordNumber = async () => {
  const {data} = await selectWordNumber()
  selectWordNumberData.value = data
}

const formatData = value => moment(value).format('YYYY年MM月DD日')
const selectUserAddNumberData = ref('')
const selectUserAddNumberDate = ref('')
const getSelectUserAddNumber = async () => {
  const {data} = await selectUserAddNumber()
  selectUserAddNumberData.value = data
  selectUserAddNumberDate.value = `${formatData(data.startTime)} 至 ${formatData(data.startEnd)}`
  const xAxisData = data.voList.map(item => item.time)
  const seriesData = data.voList.map(item => item.number)
  await nextTick()
  initCharts('selectUserAddNumberChart', xAxisData, seriesData)
}

const selectUserViewNumberData = ref('')
const selectUserViewNumberDate = ref('')
const getSelectUserViewNumber = async () => {
  const {data} = await selectUserViewNumber()
  selectUserViewNumberData.value = data
  selectUserViewNumberDate.value = `${formatData(data.startTime)} 至 ${formatData(data.startEnd)}`
  const xAxisData = data.voList.map(item => item.time)
  const seriesData = data.voList.map(item => item.number)
  await nextTick()
  initCharts('selectUserViewNumberChart', xAxisData, seriesData)
}

const init = async () => {
  getUserInfo()
  getList()
  getSelectAllRighteous()
  getSelectWordNumber()
  getSelectNumberByUser()
  getSelectUserAddNumber()
  getSelectUserViewNumber()
}

const initCharts = (id, xAxisData, seriesData) => {
  let myChart = echarts.init(document.getElementById(id))
  myChart.setOption({
    grid: {
      top: 40,
      bottom: 40,
      right: 30
    },
    tooltip: {
      trigger: 'axis'
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: xAxisData
    },
    yAxis: {
      type: 'value',
      minInterval: 1
    },
    series: [
      {
        data: seriesData,
        type: 'line',
        smooth: true,
        symbol: 'none',
        lineStyle: {
          width: 1,
          color: '#5a71fd'
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(90, 113, 253, 0.8)'
            },
            {
              offset: 1,
              color: 'rgba(90, 113, 253, 0.1)'
            }
          ])
        }
      }
    ]
  })
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
}

const echarts = inject('ec')

onMounted(() => {
  init()
})

const handleSizeChange = (size) => {
  searchForm.pageSize = size
  getList()
}
const handleCurrentChange = (page) => {
  searchForm.page = page
  getList()
}


const closeAndRefresh = (refresh) => {
  logVisible.value = false
  if (refresh) {
    getUserInfo()
  }
}

const editUserVisible = ref(false)
const editUser = () => {
  editUserVisible.value = true
}

const logVisible = ref(false)
const showLog = () => {
  logVisible.value = true
}

</script>
