import request from '@/utils/request'

/**
 * 获取随机验证码
 * @returns 
 */
export function getVerifyCode(headers) {
  return request.get(`/systemUser/getVerifyCode`, headers)
}

/**
 * 登录
 * @param {name, pwd} data 
 * @returns 
 */
export function login(data) {
  return request({
    url: `/systemUser/login`,
    method: 'post',
    data
  })
}

/**
 * 忘记密码
 * @param {name, code} data 
 * @returns 
 */
export function forgetPwd(data) {
  return request({
    url: `/systemUser/forgetPwd`,
    method: 'post',
    data
  })
}

/**
 * 发送邮箱验证码
 * @param {email, userName} params 
 * @returns 
 */
export function send(params) {
  return request({
    url: `/systemUser/send`,
    method: 'get',
    params
  })
}

/**
 * 验证邮箱码
 * @param {email, userName, code} data 
 * @returns 
 */
export function verificationCode(data) {
  return request({
    url: `/systemUser/verificationCode`,
    method: 'post',
    data
  })
}

/**
 * 邮箱密码重置
 * @param {id, pwd} data 
 * @returns 
 */
export function resetPassword(data) {
  return request({
    url: `/systemUser/resetPassword`,
    method: 'post',
    data
  })
}

/**
 * 获取当前用户信息
 * @returns 
 */
export function getUserCurren() {
  return request({
    url: `/systemUser/getUserCurren`,
    method: 'get'
  })
}

/**
 * 系统用户 - 分页查询
 * @param {page, pageSize, isDisable, createStartTime, createEndTime, name, userName, email} data 
 * @returns 
 */
export function selectByPage(data) {
  return request({
    url: `/systemUser/selectByPage`,
    method: 'post',
    data
  })
}

/**
 * 创建用户
 * @param {name, userName, password, email, isDisable} data 
 * @returns 
 */
export function add(data) {
  return request({
    url: `/systemUser/add`,
    method: 'post',
    data
  })
}

/**
 * 编辑用户
 * @param {id, name, userName, email, isDisable} data 
 * @returns 
 */
 export function update(data) {
  return request({
    url: `/systemUser/update`,
    method: 'post',
    data
  })
}

/**
 * 查看详情
 * @param {id} params 
 * @returns 
 */
export function selectById(params) {
  return request({
    url: `/systemUser/selectById`,
    method: 'get',
    params
  })
}

/**
 * 删除用户
 * @param {id} params 
 * @returns 
 */
export function deleteUser(params) {
  return request({
    url: `/systemUser/delete`,
    method: 'get',
    params
  })
}

/**
 * 启用或禁用
 * @param {id, isDisable} params 
 * @returns 
 */
export function disableUser(params) {
  return request({
    url: `/systemUser/disable`,
    method: 'get',
    params
  })
}
