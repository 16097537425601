<template>
  <div class="user-detail">
    <div class="group-name">基本信息</div>
    <el-form label-width="120px">
      <el-form-item label="用户编号：" prop="code">
        <div>{{ ruleForm.code }}</div>
      </el-form-item>
      <el-form-item label="昵称：" prop="nickname">
        <div>{{ ruleForm.nickname }}</div>
      </el-form-item>
      <el-form-item label="手机号：" prop="phone">
        <div>{{ ruleForm.phone }}</div>
      </el-form-item>
      <el-form-item label="性别：" prop="sex">
        <div>{{ ruleForm.sex == '0' ? '男': '女' }}</div>
      </el-form-item>
      <el-form-item label="生日：" prop="birthday">
        <div>{{ ruleForm.birthday }}</div>
      </el-form-item>
      <el-form-item label="常居地：" prop="province">
        <div>{{ ruleForm.province }}{{ ruleForm.city }}{{ ruleForm.district }}</div>
      </el-form-item>
      <el-form-item label="注册时间：" prop="createTime">
        <div>{{ ruleForm.createTime }}</div>
      </el-form-item>
      <el-form-item label="邀请人：" prop="inviterName">
        <div>{{ ruleForm.inviterName }}</div>
      </el-form-item>
      <!-- <el-form-item label="微信号：" prop="openId">
        <div>{{ ruleForm.openId }}</div>
      </el-form-item> -->
    </el-form>
    <div class="group-name">好友列表</div>
    <el-table :data="ruleForm.userFriendsDetailsVoList" stripe style="width: 90%; margin: 0 auto;">
      <el-table-column prop="code" label="编号" />
      <el-table-column prop="nickname" label="昵称" />
      <el-table-column prop="phone" label="手机号" />
      <el-table-column prop="inviterName" label="邀请人" />
      <el-table-column prop="inviterTime" label="邀请时间" />
      <el-table-column prop="createTime" label="注册时间" />
    </el-table>
    <div class="group-name">订单明细</div>
    <el-form label-width="120px">
      <el-form-item label="会员到期日期：" prop="membershipDueDate">
        <div>{{ ruleForm.membershipDueDate }}</div>
      </el-form-item>
      <el-form-item label="剩余钥匙数量：" prop="keyBuyNumber">
        <div>{{ruleForm.keyBuyNumber}} + {{ ruleForm.keyNumber }}</div>
      </el-form-item>
      <el-form-item label="消费总金额：" prop="money">
        <div>{{ ruleForm.money }}</div>
      </el-form-item>
    </el-form>
    <el-table :data="ruleForm.userOrderDetailsVoList" stripe style="width: 90%; margin: 0 auto;">
      <el-table-column prop="code" label="订单编号" />
      <el-table-column prop="buyContent" label="购买内容">
        <template #default="scope">
          <span v-if="scope.row.buyContent == '0'">钥匙</span>
          <span v-if="scope.row.buyContent == '1'">月卡</span>
          <span v-if="scope.row.buyContent == '2'">年卡</span>
        </template>
      </el-table-column>
      <el-table-column prop="money" label="金额(元)" />
      <el-table-column prop="buyTime" label="购买时间" />
    </el-table>
    <div class="group-name">学习进度</div>
    <el-table :data="ruleForm.userTravelDetailsVoList" stripe style="width: 90%; margin: 0 auto;">
      <el-table-column prop="id" label="旅行ID" />
      <!-- <el-table-column prop="code" label="旅行次数" /> -->
      <el-table-column prop="studyRighteousNumber" label="义场进度">
        <template #default="scope">
          <span>{{scope.row.studyRighteousNumber}}/{{scope.row.righteousNumber}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="studyWordNumber" label="单词进度">
        <template #default="scope">
          <span>{{scope.row.studyWordNumber}}/{{scope.row.wordNumber}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="studyWordNumber" label="单词进度" />
      <el-table-column prop="wrongPathNumber" label="现有迷途单词数" />
      <el-table-column prop="startTime" label="旅行开始时间" />
      <el-table-column prop="endTime" label="旅行结束时间" />
    </el-table>
  </div>
</template>

<script setup>
import { ref, defineProps, onMounted } from 'vue'
import { selectDetailsById } from '@/api/user'

const props = defineProps({
  item: Object
})

const ruleForm = ref({})
const getDetailById = async () => {
  const { id } = props.item
  const {data} = await selectDetailsById({ id })
  ruleForm.value = data
}

onMounted(() => {
  getDetailById()
})

</script>
