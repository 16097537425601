<template>
  <div class="login-panel">
    <div class="title">
      <h3 class="name">您正在找回密码</h3>
    </div>
    <div class="cont">
      <div class="login__form">
        <h4>请输入新密码</h4>
        <el-form class="form__cont" ref="ruleFormRef" :model="ruleForm" :rules="rules">
          <el-form-item prop="pwd">
            <el-input type="password" v-model="ruleForm.pwd" placeholder="请输入新密码"/>
          </el-form-item>
          <el-form-item prop="repwd">
            <el-input type="password" v-model="ruleForm.repwd" placeholder="请确认新密码"/>
          </el-form-item>
          <el-form-item class="button" style="margin-top: 50px;">
            <el-button type="primary" round @click="doConfirm(ruleFormRef)">确认修改</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { resetPassword } from '@/api/systemUser'

const ruleFormRef = ref()

const ruleForm = reactive({
  pwd: '',
  repwd: ''
})
const validatePwd = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请再次输入密码进行确认'))
  } else if (value !== ruleForm.pwd) {
    callback(new Error("两次输入的密码不一致"))
  } else {
    callback()
  }
}
const rules = reactive({
  pwd: [
    { required: true, message: '请输入用户名', trigger: 'blur' },
  ],
  repwd: [{ validator: validatePwd, trigger: 'blur' }],
})

const router = useRouter()

const doConfirm = async (formEl) => {
  if (!formEl) return
  await formEl.validate()
  const id = window.sessionStorage.getItem('id')
  let {data, message} = await resetPassword({id, ...ruleForm})
  if (message == 'OK') {
    router.replace('/login')
  }
}
</script>
