<template>
  <div class="yc-panel">
    <template v-for="(item, index) in list">
      <div :key="item.id" class="yc" v-if="index <= currentIndex">
        {{ showNameByType(item) }}
      </div>
    </template>
    <div v-if="list.length <= 0">——</div>
    <div class="yc-more" v-if="list.length - 1 > currentIndex" @click="showMore">展开</div>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue'

const props = defineProps({
  list: Array
})

const showNameByType = (item) => {
  let str = ''
  switch (item.type) {
    case 0:
      str = item.name
      break;
    case 1:
      str = `${item.levelOneName} / ${item.name}`
      break;
    case 2:
      str = `${item.levelOneName} / ${item.levelTwoName} / ${item.name}`
      break;

    default:
      return ''
      break;
  }
  return str
}

const currentIndex = ref(0)
const showMore = () => {
  currentIndex.value++
}

</script>

<style lang="scss" scoped>
.yc {
  font-size: 12px;
}
</style>
