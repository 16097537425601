import request from '@/utils/request'

/**
 * 分页查询
 * @param {page, pageSize, operator, operatingStartTime, operatingEndTime} data 
 * @returns 
 */
 export function selectByPage(data) {
  return request({
    url: `/word/selectByPage`,
    method: 'post',
    data
  })
}

/**
 * 创建
 * @param {*} data 
 * @returns 
 */
export function add(data) {
  return request({
    url: `/word/add`,
    method: 'post',
    data
  })
}

/**
 * 编辑
 * @param {*} data 
 * @returns 
 */
 export function update(data) {
  return request({
    url: `/word/update`,
    method: 'post',
    data
  })
}

/**
 * 查看详情
 * @param {id} params 
 * @returns 
 */
export function selectById(params) {
  return request({
    url: `/word/selectById`,
    method: 'get',
    params
  })
}

/**
 * 删除
 * @param {id} params 
 * @returns 
 */
export function deleteWord(params) {
  return request({
    url: `/word/delete`,
    method: 'get',
    params
  })
}

/**
 * 单词总数
 * @returns 
 */
export function selectWordNumber() {
  return request({
    url: `/word/selectWordNumber`,
    method: 'get'
  })
}

/**
 * 获得全部
 * @returns 
 */
export function selectAll(params) {
  return request({
    url: `/word/selectAll`,
    method: 'get',
    params
  })
}