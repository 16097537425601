<template>
  <div class="chk-edit-list-item" style="width: 100%">
    <el-select style="width: 156px; margin-right: 10px;" v-model="righteousFieldItem.oneLevel" filterable placeholder="请选择一级义场" @change="changeLevelOne">
      <el-option v-for="item in selectList[0]" :key="item.id" :label="item.name" :value="item.id"/>
    </el-select>
    <el-select style="width: 156px; margin-right: 10px;" v-model="righteousFieldItem.towLevel" filterable placeholder="请选择二级义场" @change="changeLevelTwo">
      <el-option v-for="item in selectList[1]" :key="item.id" :label="item.name" :value="item.id"/>
    </el-select>
    <el-select style="width: 156px;" v-model="righteousFieldItem.threeLevel" filterable placeholder="请选择三级义场" @change="changeLevelThree"> 
      <el-option v-for="item in selectList[2]" :key="item.id" :label="item.name" :value="item.id"/>
    </el-select>
    <div class="sy">
      <!-- <el-input v-model="righteousFieldItem.paraphrase" type="textarea" rows="3" placeholder="请输入释义"/> -->
      <TEditor style="width: 100%;" class="agreement-teditor" v-model="righteousFieldItem.paraphrase" />
    </div>
    <div class="close-btn" @click="deleteItem"><el-icon><Close /></el-icon></div>
  </div>
</template>

<script setup>
import { ref, reactive, defineProps, defineEmits, onMounted } from 'vue'
import { selectByType } from '@/api/righteousField'
import { Close } from '@element-plus/icons-vue'

const props = defineProps({
  list: Array,
  righteousFieldItem: Object
})

const em = defineEmits(['deleteItem'])
const deleteItem = () => {
  em('deleteItem')
}

const selectList = reactive([[...props.list], [], []])
const getSelectByType = async (params) => {
  const {data} = await selectByType(params)
  selectList[params.type] = data
} 

const changeLevelOne = (id) => {
  getSelectByType({type: 1, id})
  const current = selectList[0].find(item => item.id == id)
  if (current) {
    props.righteousFieldItem.levelOneName = current.name
  }
  selectList[1] = []
  selectList[2] = []
}
const changeLevelTwo = (id) => {
  getSelectByType({type: 2, id})
  const current = selectList[1].find(item => item.id == id)
  if (current) {
    props.righteousFieldItem.levelTwoName = current.name
  }
  selectList[2] = []
}
const changeLevelThree = (id) => {
  const current = selectList[2].find(item => item.id == id)
  if (current) {
    props.righteousFieldItem.levelThreeName = current.name
  }
}

onMounted(() => {
  const {oneLevel, towLevel} = props.righteousFieldItem
  if (oneLevel) {
    changeLevelOne(oneLevel)
  }
  if (towLevel) {
    changeLevelTwo(towLevel)
  }
})


</script>
