<template>
  <div class="login-panel">
    <div class="title">
      <h3 class="name">您正在找回密码</h3>
    </div>
    <div class="cont">
      <div class="login__form">
        <h4>验证码已发送至您的邮箱，请输入收到的验证码进行验证</h4>
        <el-form class="form__cont" ref="ruleFormRef" :model="ruleForm" :rules="rules">
          <el-form-item prop="code">
            <el-input v-model="ruleForm.code" placeholder="请输入验证码"/>
          </el-form-item>
          <el-form-item class="button" style="margin-top: 100px;">
            <el-button type="primary" round @click="doNext(ruleFormRef)">下一步</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { verificationCode } from '@/api/systemUser'

const ruleFormRef = ref()

const ruleForm = reactive({
  code: '',
  userName: window.sessionStorage.getItem('userName'),
  email: window.sessionStorage.getItem('email')
})
const rules = reactive({
  code: [
    { required: true, message: '请输入验证码"', trigger: 'blur' },
  ]
})

const router = useRouter()

const doNext = async (formEl) => {
  if (!formEl) return
  await formEl.validate()
  let {data, message} = await verificationCode(ruleForm)
  if (message == 'OK') {
    window.sessionStorage.setItem('id', data.id)
    router.push('/repassword')
  }
}

</script>
