import request from '@/utils/request'

/**
 * 查看全部收费项目
 * @returns 
 */
export function selectAll() {
  return request({
    url: `/chargeProject/selectAll`,
    method: 'get'
  })
}

/**
 * 新增费用项目
 * @param {validTime, buyContent, money, rule} data 
 * @returns 
 */
export function add(data) {
  return request({
    url: `/chargeProject/add`,
    method: 'post',
    data
  })
}

/**
 * 新增费用项目
 * @param {id, validTime, buyContent, money, rule} data 
 * @returns 
 */
 export function update(data) {
  return request({
    url: `/chargeProject/update`,
    method: 'post',
    data
  })
}
