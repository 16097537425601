<template>
  <Breadcrumb icon="icon-video" :list="['用户管理', '留言回复']"/>
  <div class="table-tools">
    <el-form :inline="true" :model="searchForm" label-width="90px" ref="ruleFormRef">
      <el-form-item label="手机号：" prop="phone">
        <el-input v-model="searchForm.phone" />
      </el-form-item>
      <el-form-item label="昵称：" prop="nickname">
        <el-input v-model="searchForm.nickname" />
      </el-form-item>
      <el-form-item label="用户编号：" prop="code">
        <el-input v-model="searchForm.code" />
      </el-form-item>
      <el-form-item label="状态：" prop="status">
        <el-radio-group v-model="searchForm.status">
          <el-radio-button :label="null">全部</el-radio-button>
          <el-radio-button :label="0">已回复</el-radio-button>
          <el-radio-button :label="1">待回复</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="留言日期：" prop="createTime">
        <el-date-picker
          value-format="YYYY-MM-DD"
          v-model="searchForm.createTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </el-form-item>
      <el-form-item class="form-item-buttons">
        <el-button type="primary" @click="getList({page: 1})">查询</el-button>
        <el-button type="default" @click="resetForm(ruleFormRef)">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
  <div class="table">
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column prop="userCode" label="用户编号" />
      <el-table-column prop="userName" label="昵称" />
      <el-table-column prop="phone" label="手机号" />
      <el-table-column prop="createTime" label="留言日期" />
      <el-table-column prop="content" label="留言内容" />
      <el-table-column prop="status" label="回复状态">
        <template #default="scope">
          <el-tag v-if="scope.row.status == 0" type="success">已回复</el-tag>
          <el-tag v-else>待回复</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button v-if="scope.row.status == 0" link type="primary" size="small" @click="editItem(scope.row, '查看详情')">查看详情</el-button>
          <el-button v-if="scope.row.status == 1" link type="primary" size="small" @click="editItem(scope.row, '回复留言')">回复留言</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page">
      <el-pagination
        v-model:currentPage="searchForm.page"
        :page-size="searchForm.pageSize"
        layout="total, prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-drawer v-model="editVisible" :title="modalTitle" direction="rtl" size="40%">
      <EditForm v-if="editVisible" @closeAndRefresh="closeAndRefresh" :item="currentItem"/>
    </el-drawer>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { selectByPage } from '@/api/leaveWord'
import EditForm from './Edit'

const ruleFormRef = ref()

const searchForm = reactive({
  page: 1,
  pageSize: 10,
  status: null,
  phone: '',
  nickname: '',
  code: '',
  createTime: ''
})

const tableData = ref([])
const total = ref(0)

const getList = async (params) => {
  Object.assign(searchForm, params)
  let startTime, endTime
  if (searchForm.createTime && searchForm.createTime.length > 1) {
    startTime = searchForm.createTime[0]
    endTime = searchForm.createTime[1]
  }
  const {data} = await selectByPage({
    startTime,
    endTime,
    ...searchForm
  })
  tableData.value = data.items
  total.value = data.total
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
  getList({page: 1})
}

onMounted(() => {
  getList()
})

const handleSizeChange = (size) => {
  searchForm.pageSize = size
  getList()
}
const handleCurrentChange = (page) => {
  searchForm.page = page
  getList()
}

const closeAndRefresh = (refresh) => {
  editVisible.value = false
  if (refresh) {
    getList()
  }
}

const editVisible = ref(false)
const currentItem = ref({})
const modalTitle = ref('')
const editItem = (item, title) => {
  modalTitle.value = title
  currentItem.value = item
  editVisible.value = true
}

</script>

