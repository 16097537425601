import request from '@/utils/request'

/**
 * 分页查询
 * @param {status, phone, nickname, code, startTime, endTime} data 
 * @returns 
 */
export function selectByPage(data) {
  return request({
    url: `/leaveWord/selectByPage`,
    method: 'post',
    data
  })
}

/**
 * 回复留言
 * @param {id, content} data 
 * @returns 
 */
export function update(data) {
  return request({
    url: `/leaveWord/update`,
    method: 'post',
    data
  })
}

/**
 * 查看详情
 * @param {id} params 
 * @returns 
 */
export function selectById(params) {
  return request({
    url: `/leaveWord/selectById`,
    method: 'get',
    params
  })
}
