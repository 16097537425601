<template>
  <div class="login">
    <div class="photo">
      <img src="@/assets/images/login-img.png" />
    </div>
    <div class="login__form">
      <h3 class="name">登录</h3>
      <el-form class="form__cont" ref="ruleFormRef" :model="ruleForm" :rules="rules">
        <el-form-item class="user" prop="name">
          <el-input v-model="ruleForm.name" placeholder="登录帐号" :prefix-icon="UserFilled"/>
        </el-form-item>
        <el-form-item class="pwd" prop="pwd">
          <el-input type="password" v-model="ruleForm.pwd" placeholder="登录密码" :prefix-icon="Lock"/>
        </el-form-item>
        <el-form-item class="link">
          <el-link type="primary" @click="linkForget">忘记密码？</el-link>
        </el-form-item>
        <el-form-item class="button">
          <el-button type="primary" round @click="doLogin(ruleFormRef)">登 录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import { UserFilled, Lock } from '@element-plus/icons-vue'
import { login } from '@/api/systemUser'

const ruleFormRef = ref()

const ruleForm = reactive({
  name: '',
  pwd: ''
})
const rules = reactive({
  name: [
    { required: true, message: '请输入登录账号', trigger: 'blur' },
  ],
  pwd: [
    { required: true, message: '请输入登录密码', trigger: 'blur' },
  ]
})

const router = useRouter()
const doLogin = async (formEl) => {
  if (!formEl) return
  await formEl.validate()
  const {message, data} = await login(ruleForm)
  if (message == 'OK') {
    window.localStorage.setItem('token', data)
    router.push('/mgr/dashboard')
  } else {
    ElMessage.error(message)
  }
}

const linkForget = () => router.push('/forget')

</script>
