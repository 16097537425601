<template>
  <Breadcrumb icon="icon-video" :list="['系统管理', '系统用户管理']"/>
  <div class="table-tools">
    <el-form :inline="true" :model="searchForm" label-width="90px" ref="ruleFormRef">
      <el-form-item label="邮箱：" prop="email">
        <el-input v-model="searchForm.email" />
      </el-form-item>
      <el-form-item label="用户名：" prop="userName">
        <el-input v-model="searchForm.userName" />
      </el-form-item>
      <el-form-item label="姓名：" prop="name">
        <el-input v-model="searchForm.name" />
      </el-form-item>
      <el-form-item label="状态：" prop="isDisable">
        <el-radio-group v-model="searchForm.isDisable">
          <el-radio-button :label="2">全部</el-radio-button>
          <el-radio-button :label="0">启用</el-radio-button>
          <el-radio-button :label="1">禁用</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="创建日期：" prop="createTime">
        <el-date-picker
          value-format="YYYY-MM-DD"
          v-model="searchForm.createTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </el-form-item>
      <el-form-item class="form-item-buttons">
        <el-button type="primary" @click="getList({page: 1})">查询</el-button>
        <el-button type="default" @click="resetForm(ruleFormRef)">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="buttons">
      <el-button class="button" type="primary" @click="addUser">创建用户</el-button>
    </div>
  </div>
  <div class="table">
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column prop="code" label="编号" />
      <el-table-column prop="userName" label="用户名" />
      <el-table-column prop="email" label="邮箱" />
      <el-table-column prop="name" label="姓名" />
      <el-table-column prop="isDisable" label="状态">
        <template #default="scope">
          <el-switch :active-value="false" :inactive-value="true" v-model="scope.row.isDisable" @change="changeDisable(scope.row)"/>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="154px"/>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button link type="primary" size="small" @click="editUser(scope.row)">编辑</el-button>
          <el-button link type="primary" size="small" @click="doDeleteUser(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page">
      <el-pagination
        v-model:currentPage="searchForm.page"
        :page-size="searchForm.pageSize"
        layout="total, prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-drawer v-model="addUserVisible" title="创建用户" direction="rtl">
      <AddForm @closeAndRefresh="closeAndRefresh"/>
    </el-drawer>
    <el-drawer v-model="editUserVisible" title="编辑用户" direction="rtl">
      <EditForm v-if="editUserVisible" @closeAndRefresh="closeAndRefresh" :item="currentUser"/>
    </el-drawer>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { selectByPage, disableUser, deleteUser } from '@/api/systemUser'
import AddForm from './Add'
import EditForm from './Edit'

const ruleFormRef = ref()

const searchForm = reactive({
  page: 1,
  pageSize: 10,
  userName: '',
  name: '',
  email: '',
  isDisable: 2,
  createTime: ''
})

const tableData = ref([])
const total = ref(0)

const getList = async (params) => {
  Object.assign(searchForm, params)
  let createStartTime, createEndTime
  if (searchForm.createTime && searchForm.createTime.length > 1) {
    createStartTime = searchForm.createTime[0]
    createEndTime = searchForm.createTime[1]
  }
  const {data} = await selectByPage({
    createStartTime,
    createEndTime,
    ...searchForm
  })
  tableData.value = data.items
  total.value = data.total
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
  getList({page: 1})
}

onMounted(() => {
  getList()
})

const handleSizeChange = (size) => {
  searchForm.pageSize = size
  getList()
}

const handleCurrentChange = (page) => {
  searchForm.page = page
  getList()
}

const changeDisable = async (item) => {
  const {id, isDisable} = item
  await disableUser({id, isDisable})
  getList()
}

const doDeleteUser = async (item) => {
  await ElMessageBox.confirm(
    '确定要删除吗?',
    '友情提示'
  )
  const {id} = item
  await deleteUser({id})
  getList()
}

const addUserVisible = ref(false)
const addUser = () => {
  addUserVisible.value = true
}

const closeAndRefresh = (refresh) => {
  addUserVisible.value = false
  editUserVisible.value = false
  if (refresh) {
    getList()
  }
}

const editUserVisible = ref(false)
const currentUser = ref({})
const editUser = (user) => {
  currentUser.value = user
  editUserVisible.value = true
}

</script>
