<template>
  <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="100px" status-icon>
    <el-form-item label="用户名：" prop="userName">
      <el-input v-model="ruleForm.userName" />
    </el-form-item>
    <el-form-item label="姓名：" prop="name">
      <el-input v-model="ruleForm.name" />
    </el-form-item>
    <el-form-item label="密码：" prop="password">
      <el-input v-model="ruleForm.password" />
    </el-form-item>
    <el-form-item label="确认密码：" prop="rePassword">
      <el-input v-model="ruleForm.rePassword" />
    </el-form-item>
    <el-form-item label="邮箱：" prop="email">
      <el-input v-model="ruleForm.email" />
    </el-form-item>
    <el-form-item label="状态：" prop="isDisable">
      <el-radio-group v-model="ruleForm.isDisable">
        <el-radio :label="false">启用</el-radio>
        <el-radio :label="true">禁用</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitForm(ruleFormRef)">确认</el-button>
      <el-button @click="resetForm(ruleFormRef)">取消</el-button>
    </el-form-item>
  </el-form>
</template>

<script setup>
import { reactive, ref, defineEmits } from 'vue'
import { add } from '@/api/systemUser'

const em = defineEmits(['closeAndRefresh'])

const ruleFormRef = ref()

const ruleForm = reactive({
  isDisable: false,
})

const validatePwd = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请再次输入密码进行确认'))
  } else if (value !== ruleForm.password) {
    callback(new Error("两次输入的密码不一致"))
  } else {
    callback()
  }
}

const rules = reactive({
  userName: [
    { required: true, message: '请输入用户名', trigger: 'blur' },
  ],
  name: [
    { required: true, message: '请输入姓名', trigger: 'blur' },
  ],
  password: [
    { required: true, message: '请输入密码', trigger: 'blur' },
  ],
  rePassword: [
    { required: true, message: '请输入确认密码', trigger: 'blur' },
    { validator: validatePwd, trigger: 'blur' },
  ],
  email: [
    { required: true, message: '请输入邮箱', trigger: 'blur' },
  ]
})

const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate()
  const {message} = await add(ruleForm)
  if (message == 'OK') {
    em('closeAndRefresh', true)
  }
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
  em('closeAndRefresh', false)
}

</script>
