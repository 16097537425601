<template>
  <Breadcrumb icon="icon-video" :list="['系统管理', '操作日志']" v-if="!isDialog"/>
  <div class="table-tools">
    <el-form :inline="true" :model="searchForm" label-width="90px" ref="ruleFormRef">
      <el-form-item label="操作人：" prop="operator" v-if="!isDialog">
        <el-input v-model="searchForm.operator" />
      </el-form-item>
      <el-form-item label="操作日期：" prop="createTime">
        <el-date-picker
          value-format="YYYY-MM-DD"
          v-model="searchForm.createTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </el-form-item>
      <el-form-item class="form-item-buttons">
        <el-button type="primary" @click="getList({page: 1})">查询</el-button>
        <el-button type="default" @click="resetForm(ruleFormRef)">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
  <div class="table">
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column prop="code" label="日志编号" />
      <el-table-column prop="operator" label="操作人" />
      <el-table-column prop="operatingAddress" label="操作IP" />
      <el-table-column prop="module" label="操作模块" />
      <el-table-column prop="operatingTime" label="操作时间" />
    </el-table>
    <div class="page">
      <el-pagination
        v-model:currentPage="searchForm.page"
        :page-size="searchForm.pageSize"
        layout="total, prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, defineProps, onMounted } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { selectByPage } from '@/api/log'

const ruleFormRef = ref()

const searchForm = reactive({
  page: 1,
  pageSize: 10,
  operator: props.operator || '',
  createTime: ''
})

const props = defineProps({
  isDialog: Boolean,
  operator: String
})

const tableData = ref([])
const total = ref(0)

const getList = async (params) => {
  Object.assign(searchForm, params)
  let operatingStartTime, operatingEndTime
  if (searchForm.createTime && searchForm.createTime.length > 1) {
    operatingStartTime = searchForm.createTime[0]
    operatingEndTime = searchForm.createTime[1]
  }
  const {data} = await selectByPage({
    operatingStartTime,
    operatingEndTime,
    ...searchForm
  })
  tableData.value = data.items
  total.value = data.total
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
  getList({page: 1})
}

onMounted(() => {
  getList()
})

const handleSizeChange = (size) => {
  searchForm.pageSize = size
  getList()
}
const handleCurrentChange = (page) => {
  searchForm.page = page
  getList()
}

</script>
