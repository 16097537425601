<template>
  <el-form class="flex-form" ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="0">
    <el-form-item prop="name">
      <div>
        <span>自由探索形态打卡：</span>
        <el-switch :active-value="true" :inactive-value="false" v-model="ruleForm.isWordsRelationExtension" />
      </div>
      <template v-if="ruleForm.isWordsRelationExtension">
        <div style="width: 100%; margin-bottom: 10px;">
          <span>关联单词：</span>
          <el-select style="width: 126px; margin-right: 10px;" placeholder="请选择"
            v-model="leftRelatedWord" :loading="leftRelatedWordLoading" filterable remote :remote-method="remoteMethod">
            <el-option v-for="item in leftSelectAllWordData" :key="item.id"
              :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <el-button type="primary" :icon="Plus" @click="addLeftRelatedWord">添加关联</el-button>
        </div>
        <WordsRelationExtension :item="w" :selectAllWordData="selectAllWordData" v-for="(w, index) in ruleForm.wordSemanticWordList" :key="w.id" @deleteItem="() => deleteWordSemanticWord(index)"/>
      </template>
    </el-form-item>
    <el-form-item prop="isDisable">
      <template v-if="ruleForm.wordRighteousFieldList.length">
        <span>导航出发形态展示：</span>
        <el-switch :active-value="true" :inactive-value="false" v-model="ruleForm.isFormRelation" />
        <template v-if="ruleForm.isFormRelation">
          <div style="width: 100%; margin-bottom: 10px;">
            <span>选择所属义场：</span>
            <el-select style="margin-right: 10px;" v-model="chooseWordRighteousField">
              <el-option v-for="(item, index) in ruleForm.wordRighteousFieldList" :key="index"
                :label="item.levelThreeName" :value="index">
                <span>{{ item.levelOneName }} / {{ item.levelTwoName }} / {{ item.levelThreeName }}</span>
                </el-option>
            </el-select>
            <el-button :icon="Plus" @click="addWordRighteousField">添加</el-button>
          </div>
          <!-- <div style="margin-bottom: 10px;">
            <el-select style="width: 126px; margin-right: 10px;" v-model="ruleForm.oneLevel" filterable placeholder="请选择一级义场" @change="changeLevelOne">
              <el-option v-for="item in selectList[0]" :key="item.id" :label="item.name" :value="item.id"/>
            </el-select>
            <el-select style="width: 126px; margin-right: 10px;" v-model="ruleForm.towLevel" filterable placeholder="请选择二级义场" @change="changeLevelTwo">
              <el-option v-for="item in selectList[1]" :key="item.id" :label="item.name" :value="item.id"/>
            </el-select>
            <el-select style="width: 126px; margin-right: 10px;" v-model="ruleForm.threeLevel" filterable placeholder="请选择三级义场">
              <el-option v-for="item in selectList[2]" :key="item.id" :label="item.name" :value="item.id"/>
            </el-select>
            <el-button :icon="Plus" @click="addWordRighteousField">添加</el-button>
          </div> -->
          <WordMorphological v-for="(item, index) in wordMorphologicalList" :key="index" :item="item" :selectAllWordData="selectAllWordData" @deleteItem="() => wordMorphologicalList.splice(index, 1)"/>
        </template>
      </template>
    </el-form-item>
    <el-form-item>
      <el-button @click="resetForm(ruleFormRef)">取消</el-button>
      <el-button type="primary" @click="submitForm(ruleFormRef)">确认</el-button>
    </el-form-item>
  </el-form>
</template>

<script setup>
import { reactive, ref, defineProps, defineEmits, onMounted } from 'vue'
import { Plus } from '@element-plus/icons-vue'
import { selectByType } from '@/api/righteousField'
import { selectAll as selectAllWord } from '@/api/word'
import { ElMessage } from 'element-plus'

const em = defineEmits(['close'])

const props = defineProps({
  item: Object
})

const ruleFormRef = ref()

const ruleForm = ref({...props.item})

const rules = reactive({})

const selectAllWordData = ref([])
const getSelectAllWord = async () => {
  const {data} = await selectAllWord({name: ''})
  selectAllWordData.value = data
}

const leftRelatedWordLoading = ref(false)
const leftSelectAllWordData = ref([])
const remoteMethod = async (name) => {
  if (name !== '') {
      leftRelatedWordLoading.value = true
      const {data} = await selectAllWord({name})
      leftRelatedWordLoading.value = false
      leftSelectAllWordData.value = data
    } else {
      leftSelectAllWordData.value = []
    }
}

const leftRelatedWord = ref('')
const addLeftRelatedWord = () => {
  if (leftRelatedWord.value) {
    ruleForm.value.wordSemanticWordList.push({
      relatedWordId: leftRelatedWord.value,
      gameType: 0,
      fileList: [],
      fileList2: []
    })
    leftRelatedWord.value = ''
  }
}

const deleteWordSemanticWord = (index) => {
  ruleForm.value.wordSemanticWordList.splice(index, 1)
}

const chooseWordRighteousField = ref()

const addWordRighteousField = () => {
  const id = chooseWordRighteousField.value
  if (id > -1) {
    const current = ruleForm.value.wordRighteousFieldList[id]
    if (current) {
        wordMorphologicalList.value.push({
          levelOne: current.oneLevel,
          levelOneName: current.levelOneName,
          levelTow: current.towLevel,
          levelTwoName: current.levelTwoName,
          levelThree: current.threeLevel,
          levelThreeName: current.levelThreeName,
          words: []
        })
    }
    chooseWordRighteousField.value = ''
  }
  // if (ruleForm.value.threeLevel) {
  //   wordMorphologicalList.value.push({
  //     levelOne: ruleForm.value.oneLevel,
  //     levelOneName: selectList[0].find(item => item.id == ruleForm.value.oneLevel).name,
  //     levelTow: ruleForm.value.towLevel,
  //     levelTwoName: selectList[1].find(item => item.id == ruleForm.value.towLevel).name,
  //     levelThree: ruleForm.value.threeLevel,
  //     levelThreeName: selectList[2].find(item => item.id == ruleForm.value.threeLevel).name,
  //     words: []
  //   })
  // }
}


const wordMorphologicalList = ref([])
onMounted(() => {
  getSelectByType({type: 0})
  getSelectAllWord()
  ruleForm.value = {...props.item}
  console.log(ruleForm.value)
  const wList = [...ruleForm.value.wordMorphologicalRighteousList]
  wList.map(w => {
    w.words = ruleForm.value.wordMorphologicalList.filter(item => item.righteousFileldId == w.levelThree)
  })
  wordMorphologicalList.value = wList

  const {wordSemanticWordList} = ruleForm.value
  if (wordSemanticWordList && wordSemanticWordList.length > 0) {
    wordSemanticWordList.map(item => {
      const fileList = []
      if (item.rightPicture) {
        item.rightPicture.map(r => {
          fileList.push({
            name: r, url: r
          })
        })
      }
      item.fileList = fileList
      const fileList2 = []
      if (item.errorPicture) {
        item.errorPicture.map(r => {
          fileList2.push({
            name: r, url: r
          })
        })
      }
      item.fileList2 = fileList2
    })
  }
})

const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate()
  let hasError = false
  ruleForm.value.wordSemanticWordList.map(item => {
    if (item.gameType == '0') {
      if ((!item.rightPicture || !item.rightPicture.length) && (!item.fileList || !item.fileList.length)) {
        ElMessage.error('关联单词的正确图片不能空')
        hasError = true
      }
      if ((!item.errorPicture || !item.errorPicture.length) && (!item.fileList2 || !item.fileList2.length)) {
        ElMessage.error('关联单词的错误图片不能空')
        hasError = true
      }
    }
  })
  if (hasError) return
  const wordMorphologicalRighteousListTemp = []
  const wordMorphologicalListTemp = []
  wordMorphologicalList.value.map(w => {
    wordMorphologicalRighteousListTemp.push({
      levelOne: w.levelOne,
      levelTow: w.levelTow,
      levelThree: w.levelThree
    })
    w.words.map(wd => {
      wordMorphologicalListTemp.push(wd)
    })
  })
  props.item.wordMorphologicalRighteousList = wordMorphologicalRighteousListTemp
  props.item.wordMorphologicalList = wordMorphologicalListTemp
  props.item.isFormRelation = ruleForm.value.isFormRelation
  props.item.isWordsRelationExtension = ruleForm.value.isWordsRelationExtension
  console.log(props.item)
  em('close', false)
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
  em('close', false)
}

const selectList = reactive([[], [], []])
const getSelectByType = async (params) => {
  const {data} = await selectByType(params)
  selectList[params.type] = data
} 

const changeLevelOne = (id) => {
  getSelectByType({type: 1, id})
  selectList[1] = []
  selectList[2] = []
}
const changeLevelTwo = (id) => {
  getSelectByType({type: 2, id})
  selectList[2] = []
}


</script>
