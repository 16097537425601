import request from '@/utils/request'

/**
 * 分页查询
 * @param {levelOne, levelTwo, levelThree, type} data 
 * @returns 
 */
export function selectByPage(data) {
  return request({
    url: `/righteousField/selectByPage`,
    method: 'post',
    data
  })
}

/**
 * 新增义场
 * @param {name, type, levelOne, levelTwo} data 
 * @returns 
 */
 export function add(data) {
  return request({
    url: `/righteousField/add`,
    method: 'post',
    data
  })
}

/**
 * 编辑义场
 * @param {id, name, type, levelOne, levelTwo} data 
 * @returns 
 */
export function update(data) {
  return request({
    url: `/righteousField/update`,
    method: 'post',
    data
  })
}

/**
 * 删除义场
 * @param {id} params 
 * @returns 
 */
export function deleteRighteousField(params) {
  return request({
    url: `/righteousField/delete`,
    method: 'get',
    params
  })
}

/**
 * 查看详情
 * @param {id} params 
 * @returns 
 */
export function selectById(params) {
  return request({
    url: `/righteousField/selectById`,
    method: 'get',
    params
  })
}

/**
 * 根据类型和名字和上级id查出义场
 * @param {id, name, type} data 
 * @returns 
 */
 export function selectByType(data) {
  return request({
    url: `/righteousField/selectByType`,
    method: 'post',
    data
  })
}

/**
 * 义场总数
 * @returns 
 */
 export function selectAllRighteous() {
  return request({
    url: `/righteousField/selectAllRighteous`,
    method: 'get'
  })
}
/**
 * 查看义场下的单词排序
 * @returns 
 */
 export function selectSortByWord(params) {
  return request({
    url: `/righteousField/selectSortByWord`,
    method: 'get',
    params
  })
}
/**
 * 编辑义场下的单词排序
 * @param {wordParams,threeLevel} data 
 * @returns 
 */
 export function updateSortByWord(data) {
  return request({
    url: `/righteousField/sortByWord`,
    method: 'post',
    data
  })
}