import request from '@/utils/request'

/**
 * 分页查询
 * @param {title} data 
 * @returns 
 */
export function selectByPage(data) {
  return request({
    url: `/banner/selectByPage`,
    method: 'post',
    data
  })
}

/**
 * 创建banner
 * @param {title, photo, content, isDisable} data 
 * @returns 
 */
 export function add(data) {
  return request({
    url: `/banner/add`,
    method: 'post',
    data
  })
}

/**
 * 编辑banner
 * @param {id, title, photo, content, isDisable} data 
 * @returns 
 */
export function update(data) {
  return request({
    url: `/banner/update`,
    method: 'post',
    data
  })
}

/**
 * 删除
 * @param {id} params 
 * @returns 
 */
export function deleteBanner(params) {
  return request({
    url: `/banner/delete`,
    method: 'get',
    params
  })
}

/**
 * 查看详情
 * @param {id} params 
 * @returns 
 */
export function selectById(params) {
  return request({
    url: `/banner/selectById`,
    method: 'get',
    params
  })
}

/**
 * 启用禁用
 * @param {id, isDisable} params 
 * @returns 
 */
 export function disableBanner(params) {
  return request({
    url: `/banner/disable`,
    method: 'get',
    params
  })
}

/**
 * 排序
 * @param {id, sort} params 
 * @returns 
 */
 export function sortBanner(params) {
  return request({
    url: `/banner/sort`,
    method: 'get',
    params
  })
}