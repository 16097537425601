<template>
  <div class="chk-edit-list-item">
    <div class="item">
      <div class="tit">义场名称：  </div>
      <div class="con">{{ item.levelOneName }} / {{ item.levelTwoName }} / {{ item.levelThreeName }}</div>
    </div>
    <div class="item">
      <div class="tit">关联单词：</div>
      <div class="con">
        <el-select style="width: 126px; margin-right: 10px;"
          v-model="relatedWordId" placeholder="请选择" :loading="rightRelatedWordLoading" filterable remote :remote-method="remoteMethod">
          <el-option v-for="item in rightSelectAllWordData" :key="item.id"
            :label="item.name" :value="item.id">
          </el-option>
        </el-select>
        <el-button type="primary" :icon="Plus" @click="addWordRighteousField">添加关联</el-button>
      </div>
    </div>
    <el-table :data="item.words" stripe style="width: 100%">
      <el-table-column type="index" label="序号" width="55"/>
      <el-table-column prop="relatedWordId" label="关联单词名称">
        <template #default="scope">
          {{ showNameById(scope.row.relatedWordId, selectAllWordData) }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button link type="primary" size="small" @click="deleteWord(scope.$index)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="close-btn" @click="deleteItem"><el-icon><Close /></el-icon></div>
  </div>
</template>

<script setup>
import { ref, reactive, defineProps, defineEmits, onMounted } from 'vue'
import { selectByType } from '@/api/righteousField'
import { Close, Plus } from '@element-plus/icons-vue'
import { selectAll as selectAllWord } from '@/api/word'

const props = defineProps({
  selectAllWordData: Array,
  item: Object
})

const em = defineEmits(['deleteItem'])
const deleteItem = () => {
  em('deleteItem')
}

onMounted(() => {

})

const showNameById = (value, list) => {
  if (!value) return ''
  const cur = list.find(item => item.id == value)
  if (!cur) return ''
  return cur.name
}

const relatedWordId = ref('')
const addWordRighteousField = () => {
  if (relatedWordId.value) {
    props.item.words.push({
      righteousFileldId: props.item.levelThree,
      relatedWordId: relatedWordId.value
    })
    relatedWordId.value = ''
  }
}

const ruleForm = ref({})

const tableData = ref([])

const deleteWord = (index) => props.item.words.splice(index, 1)

const rightRelatedWordLoading = ref(false)
const rightSelectAllWordData = ref([])
const remoteMethod = async (name) => {
  if (name !== '') {
      rightRelatedWordLoading.value = true
      const {data} = await selectAllWord({name})
      rightRelatedWordLoading.value = false
      rightSelectAllWordData.value = data
    } else {
      rightSelectAllWordData.value = []
    }
}


</script>
