<template>
  <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="140px" status-icon>
    <template v-if="type == 0">
      <el-form-item label="一级义场名称：" prop="name">
        <el-input v-model="ruleForm.name" />
      </el-form-item>
    </template>
    <template v-if="type == 1">
      <el-form-item label="所属一级义场：" prop="levelOne">
        <el-select v-model="ruleForm.levelOne" filterable placeholder="请选择">
          <el-option v-for="item in levelTwoSelect" :key="item.id" :label="item.name" :value="item.id"/>
        </el-select>
      </el-form-item>
      <el-form-item label="二级义场名称：" prop="name">
        <el-input v-model="ruleForm.name" />
      </el-form-item>
    </template>
    <template v-if="type == 2">
      <el-form-item label="所属一级义场：" prop="levelOne">
        <el-select v-model="ruleForm.levelOne" filterable placeholder="请选择" @change="changeLevelTwo">
          <el-option v-for="item in levelTwoSelect" :key="item.id" :label="item.name" :value="item.id"/>
        </el-select>
      </el-form-item>
      <el-form-item label="所属二级义场：" prop="levelTwo">
        <el-select v-model="ruleForm.levelTwo" filterable placeholder="请选择">
          <el-option v-for="item in levelThreeSelect" :key="item.id" :label="item.name" :value="item.id"/>
        </el-select>
      </el-form-item>
      <el-form-item label="三级义场名称：" prop="name">
        <el-input v-model="ruleForm.name" />
      </el-form-item>
    </template>
    <el-form-item label="排序：" prop="sort">
      <el-input v-model="ruleForm.sort" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitForm(ruleFormRef)">保存</el-button>
      <el-button @click="saveAndAgain(ruleFormRef)">保存并继续新增</el-button>
    </el-form-item>
  </el-form>
</template>

<script setup>
import { reactive, ref, defineEmits, defineProps, onMounted } from 'vue'
import { add, selectByType } from '@/api/righteousField'
import { ElMessage } from 'element-plus'

const props = defineProps({
  type: Number | String,
  selectList: Array
})

const em = defineEmits(['closeAndRefresh'])

const ruleFormRef = ref()

const ruleForm = reactive({
  type: props.type,
})

const rules = reactive({
  name: [
    { required: true, message: '请输入义场名称', trigger: 'blur' },
  ],
  sort: [
    { required: true, message: '请输入排序', trigger: 'blur' },
  ]
})
if (props.type > 0) {
  rules.levelOne = [
    { required: true, message: '请选择一级义场', trigger: 'change' },
  ]
}
if (props.type > 1) {
  rules.levelTwo = [
    { required: true, message: '请选择二级义场', trigger: 'change' },
  ]
}

onMounted(() => {
  getSelectByType({type: 0})
})

const submitForm = async (formEl, visible) => {
  if (!formEl) return
  await formEl.validate()
  const {message} = await add(ruleForm)
  if (message == 'OK') {
    em('closeAndRefresh', true, visible)
  }
}

const saveAndAgain = async (formEl) => {
  await submitForm(formEl, true)
  await resetForm(formEl, true)
  ElMessage.success('新增成功')
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
}

const getSelectByType = async (params) => {
  const {data} = await selectByType(params)
  if (params.type == 0) {
    levelTwoSelect.value = data
  } else if (params.type == 1) {
    levelThreeSelect.value = data
  }
} 
const levelTwoSelect = ref([])
const levelThreeSelect = ref([])
const changeLevelTwo = (id) => {
  getSelectByType({type: 1, id})
  if (props.type == 2) {
    ruleForm.levelTwo = ''
  }
}

</script>
