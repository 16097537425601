import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '@/views/login'
import Forget from '@/views/login/forget.vue'
import GetBack from '@/views/login/getBack.vue'
import RePassword from '@/views/login/rePassword.vue'
import Layout from '@/views/mgr/layout'
import Home from '@/views/mgr/Home.vue'
import YC from '@/views/mgr/content/yc'
import CHK from '@/views/mgr/content/chk'
import Banner from '@/views/mgr/content/banner'
import CK from '@/views/mgr/content/ck'
import UserList from '@/views/mgr/user/list'
import UserStudy from '@/views/mgr/user/study'
import UserMessahe from '@/views/mgr/user/message'
import UserOrder from '@/views/mgr/user/order'
import SystemUser from '@/views/mgr/system/user'
import SystemAgreement from '@/views/mgr/system/agreement'
import SystemLog from '@/views/mgr/system/log'

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', name: 'Login', component: Login },
  { path: '/forget', name: 'Forget', component: Forget },
  { path: '/getback', name: 'GetBack', component: GetBack },
  { path: '/repassword', name: 'RePassword', component: RePassword },
  { path: '/mgr', component: Layout, redirect: '/mgr/dashboard', 
    children: [
      { path: 'dashboard', name: 'Home', component: Home },
      { path: 'content/yc', name: 'YC', component: YC },
      { path: 'content/chk', name: 'CHK', component: CHK },
      { path: 'content/banner', name: 'Banner', component: Banner },
      { path: 'content/ck', name: 'CK', component: CK },
      { path: 'user/list', name: 'UserList', component: UserList },
      { path: 'user/study', name: 'UserStudy', component: UserStudy },
      { path: 'user/message', name: 'UserMessahe', component: UserMessahe },
      { path: 'user/order', name: 'UserOrder', component: UserOrder },
      { path: 'system/user', name: 'SystemUser', component: SystemUser },
      { path: 'system/agreement', name: 'SystemAgreement', component: SystemAgreement },
      { path: 'system/log', name: 'SystemLog', component: SystemLog },
    ]
  },
  { path: '/:pathMatch(.*)', redirect: '/login'}
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
