import request from '@/utils/request'

/**
 * 分页查询
 * @param {name} data 
 * @returns 
 */
export function selectByPage(data) {
  return request({
    url: `/lexicon/selectByPage`,
    method: 'post',
    data
  })
}

/**
 * 添加词库
 * @param {name, isDisable} data 
 * @returns 
 */
 export function add(data) {
  return request({
    url: `/lexicon/add`,
    method: 'post',
    data
  })
}

/**
 * 编辑词库
 * @param {id, name, isDisable} data 
 * @returns 
 */
export function update(data) {
  return request({
    url: `/lexicon/update`,
    method: 'post',
    data
  })
}

/**
 * 删除
 * @param {id} params 
 * @returns 
 */
export function deleteLexicon(params) {
  return request({
    url: `/lexicon/delete`,
    method: 'get',
    params
  })
}

/**
 * 查看详情
 * @param {id} params 
 * @returns 
 */
export function selectById(params) {
  return request({
    url: `/lexicon/selectById`,
    method: 'get',
    params
  })
}

/**
 * 启用禁用
 * @param {id, isDisable} params 
 * @returns 
 */
 export function disableLexicon(params) {
  return request({
    url: `/lexicon/disable`,
    method: 'get',
    params
  })
}

/**
 * 排序
 * @param {id, sort} params 
 * @returns 
 */
 export function sortLexicon(params) {
  return request({
    url: `/lexicon/sort`,
    method: 'get',
    params
  })
}

/**
 * 获得全部词库
 * @returns 
 */
 export function selectAll() {
  return request({
    url: `/lexicon/selectAll`,
    method: 'get'
  })
}
