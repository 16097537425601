import request from '@/utils/request'

/**
 * 分页查询
 * @param {page, pageSize, code, isMember, nickname, phone, createStartTime, createEndTime, province, city, district, membershipDueStartDate, membershipDueEndDate} data 
 * @returns 
 */
export function selectByPage(data) {
  return request({
    url: `/user/selectByPage`,
    method: 'post',
    data
  })
}

/**
 * 查看详情
 * @param {id} params 
 * @returns 
 */
export function selectDetailsById(params) {
  return request({
    url: `/user/selectDetailsById`,
    method: 'get',
    params
  })
}

/**
 * 现有用户数
 * @returns 
 */
 export function selectNumberByUser() {
  return request({
    url: `/user/selectNumberByUser`,
    method: 'get'
  })
}

/**
 * 用户新增情况分析
 * @returns 
 */
 export function selectUserAddNumber() {
  return request({
    url: `/user/selectUserAddNumber`,
    method: 'get'
  })
}

/**
 * 用户浏览量分析
 * @returns 
 */
 export function selectUserViewNumber() {
  return request({
    url: `/user/selectUserViewNumber`,
    method: 'get'
  })
}

/**
 * 用户邀请排行榜
 * @returns 
 */
export function selectInviterUser() {
  return request({
    url: `/user/selectInviterUser`,
    method: 'get'
  })
}

/**
 * 学习排行
 * @param {page, pageSize, time} data 
 * @returns 
 */
 export function selectStudyRanking(data) {
  return request({
    url: `/user/selectStudyRanking`,
    method: 'post',
    data
  })
}

/**
 * 省市区联动
 * @param {id} params 
 * @returns 
 */
 export function regionTree(params) {
  return request({
    url: `/wechat/user/regionTree`,
    method: 'get',
    params
  })
}